import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  ButtonGroup,
  useToast,
  Text,
  ModalFooter,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { ReloadIcon } from "../../../TaxKanban/Icons/Reload";
import { RetrieveCertificatesStyle } from "../../../../../styles/components/RetrieveCertificates";
import { getCertificateLimit } from "../../../../../_services/client.service";
import { ICertificate } from "../../../../../_services/interface/certificate.interface";
import { Filters, FilterDataSelected, FilterTypeEnum } from "../../../../../components/Filters";
import { IColumn, IData, ITableMetaData } from "../../../../../components/Table/table.interface";
import { entityTags, errorMessage, jurisdictionTags, stateRegionForTag, validFilterField } from "../../../../../components/Table/utils";
import { Datatables } from "../../../../../components/Table/Datatables";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { taxCurrentAccountFilter, taxCurrentAccountFilterForBulkUpdate } from "../../util/TaxCurrentAccountPanel";
import { taxCurrentAccountCertificateStatusForTagList, taxCurrentAccountStatusForTagList } from "../../util/TaxCurrentAccountUtils";
import { IFilterListTaxCurrentAccount, ITaxCurrentAccountListData } from "../../../../../_services/interface/taxCurrentAccount.interface";
import { issueBatchTaxCurrentAccountCompanies, listTaxCurrentAccount } from "../../../../../_services/taxCurrentAccount.service";

interface IRetrieveTaxCurrentAccountProps {
  guid_client: string;
  certificates: ICertificate[];
  setBulkUpdatedStarted: () => void;
  canShowRetrieve: boolean;
}

export const RetrieveTaxCurrentAccount = (props: IRetrieveTaxCurrentAccountProps) => {
  const toast = useToast();
  const { validate } = useLoginFormStore();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenConfirmation, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [proceedDisabled, setProceedDisabled] = useState<boolean>(true);
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  
  // Certificate limit
  const limitFixed = 4;
  const [certificateLimit, setCertificateLimit] = useState<number>(limitFixed);

  // Filter and certificate data
  const [taxCurrentAccountData, setTaxCurrentAccountData] = useState<IData<ITaxCurrentAccountListData>|null>(null);
  const [taxCurrentAccountDataToSearch, setTaxCurrentAccountDataToSearch] = useState<ITaxCurrentAccountListData[]>([]);
  const [taxCurrentAccountDataTotal, setTaxCurrentAccountDataTotal] = useState<number>(0);

  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();

  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  useEffect(() => {
    if (filterDataSelected) {
      const areFiltersSelected = taxCurrentAccountFilter.some(row => {
        return row.rowFields.some(field => {
          if(Array.isArray(filterDataSelected[field.name]) && !filterDataSelected[field.name][0]) {
            return false;

          }
          return filterDataSelected.hasOwnProperty(field.name) && filterDataSelected[field.name].length > 0;
        });
      });

      if(areFiltersSelected) {
        fetchListTaxCurrentAccountData();
        setProceedDisabled(false);
      } else {
        setTaxCurrentAccountData(null);
        setTaxCurrentAccountDataTotal(0);
        setProceedDisabled(true);
      }
    } else {
      setTaxCurrentAccountData(null);
      setTaxCurrentAccountDataTotal(0);
      setProceedDisabled(true);
    }
  }, [filterDataSelected, metaData]);

  useEffect(() => {
    fetchCertificateLimit();
  }, []);

  const retrieve = async () => {
    setLoadingState(true);
    if (certificateLimit < limitFixed || validate?.email.includes('@taxly.com.br')) {
      const bachData = taxCurrentAccountDataToSearch.map((taxCurrentAccount: ITaxCurrentAccountListData) => {
        return {
          guid_company: taxCurrentAccount.company.guid_company,
          slug: taxCurrentAccount.slug,
        }
      });

      const { status, response } = await issueBatchTaxCurrentAccountCompanies(props.guid_client, bachData);

      if (status === 200) {
        props.setBulkUpdatedStarted();
        onCloseConfirmation();
        onClose();

      } else if (status === 401) {
        toast({
          title: 'Limite de consultas excedidas',
          description: 'Você atingiu a cota mensal de 4 consultas.',
          status: 'warning',
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar obter as informações das certidões',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Limite de consultas excedidas',
        description: 'Você atingiu a cota mensal de 4 consultas.',
        status: 'warning',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  const fetchListTaxCurrentAccountData = async () => {
    setLoadingState(true);
  
    const { status, response } = await listTaxCurrentAccount(props.guid_client as string, getFilter());

    if (status === 200 && 'meta' in response) {

      if (response.meta.pageCount !== undefined && response.data !== undefined) {
        setTaxCurrentAccountData(response);
        setTaxCurrentAccountDataTotal(response.meta.totalCount);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar obter as informações das certidões',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar obter as informações das certidões',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  const fetchtaxCurrentAccountDataToSearch = async () => {
    let filters = getFilter();
    filters = {
      ...filters,
      page: 1,
      row_by_page: -1,
    };

    const { status, response } = await listTaxCurrentAccount(props.guid_client, filters);

    if (status === 200 && 'meta' in response) {
      if (response.meta.pageCount !== undefined && response.data !== undefined) {
        const data: ITaxCurrentAccountListData[] = response.data;

        setTaxCurrentAccountDataToSearch(data);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar obter as informações das certidões',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar obter as informações das certidões',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const fetchCertificateLimit = async () => {
    setLoadingState(true);
    const { status, response } = await getCertificateLimit(props.guid_client);

    if (status === 200) {
      setCertificateLimit(response);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar obter as informações de consulta de certidão',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }

    setLoadingState(false);
  }

  const getFilter = (): IFilterListTaxCurrentAccount => {

    // const startCreatedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.startCreatedAtFilter][0] : undefined;
    // const endCreatedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.endCreatedAtFilter][0] : undefined;

    const companies: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [];
    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const companyTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [];
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    const taxCurrentAccountStatuses: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountStatusFilter] : [];
    const taxCurrentAccountTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountTypeFilter] : [];
    const jurisdictions: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const taxCurrentAccountStates: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountStateFilter] : [];
    const taxCurrentAccountCities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountCityFilter] : [];
    const taxCurrentAccountOrigin: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountOriginFilter] : [];
    const searchTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.searchTypeFilter] : [];

    const filters: IFilterListTaxCurrentAccount = {
      page: metaData?.currentPage ? Number(metaData.currentPage) : 1,
      row_by_page: metaData?.rowByPage ? Number(metaData?.rowByPage) : -1,
      order_field: metaData?.orderField ?? 'queryDate',
      order_direction: metaData?.orderDirection ?? 'desc',
      search_types: "0",
      
      // ...(validFilterField(startCreatedAtDate) ? { initial_created_at_date: startCreatedAtDate } : {}),
      // ...(validFilterField(endCreatedAtDate) ? { final_created_at_date: endCreatedAtDate } : {}),

      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(companyTypes) ? { company_types: companyTypes.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(taxCurrentAccountStatuses) ? { status: taxCurrentAccountStatuses.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountTypes) ? { slugs: taxCurrentAccountTypes.join(',') } : {}),
      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountStates) ? { id_states: taxCurrentAccountStates.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountCities) ? { id_cities: taxCurrentAccountCities.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountOrigin) ? { origins: taxCurrentAccountOrigin.join(',') } : {}),
      ...(validFilterField(searchTypes) ? { search_types: searchTypes.join(',') } : {}),
    };

    return filters;
  }

  const openModal = () => {
    fetchCertificateLimit();
    onOpen();
  }

  const closeModal = () => {
    onClose();
  }

  const validateProceedButton = () => {
    return isLoading || proceedDisabled || (taxCurrentAccountData ? taxCurrentAccountDataTotal === 0 : true);
  }

  const onOpenConfirmationModal = async () => {
    setProceedLoading(true);
    await fetchtaxCurrentAccountDataToSearch();
    setProceedLoading(false);
    onOpenConfirmation();
  };
  
  const columns = [
    {
      title: 'Consulta',
      name: 'tax_current_account_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Origem - Órgão',
      name: 'origin_name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: taxCurrentAccountStatusForTagList,
      },
    },
    {
      title: 'Empresa - cód',
      name: 'company.displayName',
      orderFieldName: 'company.name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Tipo',
      name: 'company.type',
      orderFieldName: 'company.type',
      sortable: true,
      align: 'center',
      tags: entityTags('number'),
    },
    {
      title: 'CNPJ',
      name: 'company.cnpjFormatted',
      orderFieldName: 'company.cnpj',
      sortable: true,
      align: 'center',
      icon: (row: ITaxCurrentAccountListData) => errorMessage(row),
    },
    {
      title: 'Grupo',
      name: 'groups_names',
      orderFieldName: 'group_names',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Data de Consulta',
      name: 'createdAtFormatted',
      orderFieldName: 'queryDate',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: jurisdictionTags(),
    },
    {
      title: 'Estado',
      name: 'state.initials',
      orderFieldName: 'state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state.initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city.name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Status - Certidão',
      name: 'certificate.status.name',
      sortable: false,
      align: 'center',
      dynamicTag: {
        function: taxCurrentAccountCertificateStatusForTagList,
      },
    },
    {
      title: 'Validade - Certidão',
      name: 'certificate.valid_until_formatted',
      sortable: false,
      align: 'center',
    },
  ] as IColumn[];

  return (
    <Flex>
      <ButtonGroup onClick={openModal} size="sm" mt="24px" isAttached color="white">
        <Button leftIcon={<ReloadIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }} disabled={!props.canShowRetrieve} isDisabled={!props.canShowRetrieve}>
          Atualizar Conta Corrente Fiscal
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="7xl" >
        <RetrieveCertificatesStyle>
          <ModalOverlay className="modal-overlay" />
          <ModalContent width={1487} className="modal-content" >
            <ModalHeader width='100%' padding="8px 24px !important">
              <Text className="title">Atualizar Conta Corrente Fiscal em Lote</Text>
              <Text className="description">
                <div className="description-text">
                  <Flex className="blue-text">Selecione abaixo os critérios para atualização em lote de consultas de conta corrente fiscal. A lista de consultas exibida abaixo será atualizada de acordo com os filtros aplicados.</Flex>
                </div>
                <div className="limit-box" style={{display: 'flex'}}>
                  <Flex className="limit-message" marginRight="5px">
                    <p>Restam <span className="blue-text"> {validate?.email.includes('@taxly.com.br') ? '∞' : limitFixed - certificateLimit} de {validate?.email.includes('@taxly.com.br') ? '∞' : limitFixed}</span> atualizações mensais disponíveis.</p>
                  </Flex>
                  <Tooltip label='Por padrão, a taxly permite até 04 atualizações mensais disponíveis. Caso deseje aumentar esse limite, entre em contato com suporte@taxly.com.br'>
                    <Image height="20px" width="20px" src="/icons/information-circle-blue.svg" resize="none"/>
                  </Tooltip>
                </div>
              </Text>
              { 
              certificateLimit >= limitFixed ? 
                <Flex margin="12px 0" width={1389} border='2px solid #268DF4' background="#F4F4FB" display="flex" alignItems="center" justifyContent="flex-start">
                <Image margin="16px 24px" height="24px" width="24px" src="/icons/information-circle-blue.svg" resize="none"/>

                <Text fontFamily="Poppins-Medium" color="#454954" fontSize="14px" fontWeight="400">Você ultrapassou a sua cota. Entre em contato no e-mail <a style={{color: "blue"}} href="mailto:suporte@taxly.com.br">suporte@taxly.com.br</a> para maiores dúvidas.</Text>
              </Flex>
              : <></>
              }

            </ModalHeader>

            <ModalCloseButton /> 

            <ModalBody fontFamily="Poppins-Medium">
              <Filters
                filters={taxCurrentAccountFilterForBulkUpdate}
                certificates={props.certificates}
                selectedValuesHook={setFilterDataSelected}
              />

              {proceedDisabled ? 
              (
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  mt={4}
                >
                  <Text
                    style={{
                      fontFamily: 'Poppins-Medium',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '140%',
                      color: '#171923'
                    }}
                  >
                    Não há filtros selecionados para exibir registros
                  </Text>
                </Flex>
              ) 
              : (
                <>
                  <Flex flexDirection="column" mt="15px">
                    <Datatables<any>
                      name="Relatórios"
                      columns={columns}
                      metaDataHook={setMetaData}
                      data={taxCurrentAccountData}
                      isLoading={isLoading}
                      showTotalRows={true}
                      heigth="400px"
                    />
                  </Flex>
                </>
              )}
            </ModalBody> 
            <ModalFooter>
              <Flex width="100%" justifyContent="end" alignItems="flex-end" display="flex">
                <FormButton isLoading={proceedLoading} onClick={onOpenConfirmationModal} width="135px" disabled={validateProceedButton()}>
                  <Tooltip label={proceedDisabled ? "É necessário adicionar algum filtro para prosseguir" : ''}>
                    Prosseguir
                  </Tooltip>
                </FormButton> 
              </Flex>
            </ModalFooter>
          </ModalContent>
        </RetrieveCertificatesStyle>
        <Modal closeOnOverlayClick={true} isCentered isOpen={isOpenConfirmation} onClose={onCloseConfirmation} size="sm">
          <ModalOverlay className="modal-overlay" />
          <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
            <ModalHeader fontFamily="Poppins-SemiBold">Atualizar consultas em Lote</ModalHeader>
            <ModalCloseButton />
            <ModalBody fontFamily="Poppins-medium" fontSize={12}>
              <Flex justifyContent="center" flexDirection="column" gap={5}>
                <Flex flexDirection="column" gap={5}>
                  <Text>
                  Ao confirmar, o sistema realizará a pesquisa para  <strong>{taxCurrentAccountDataTotal}</strong> {taxCurrentAccountDataTotal === 1 ? 'consulta' : 'consultas'}.
                  </Text>
                </Flex>
                <Flex gap={2} flexGrow={1} justifyContent="space-between">
                  <Button isDisabled={isLoading} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={retrieve}>Confirmar</Button>
                  <Button isDisabled={isLoading} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={() => onCloseConfirmation()}>Cancelar</Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Modal>
      
    </Flex>
  );
};
