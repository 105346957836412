import { Flex, Image, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { deleteCND } from "../../../../_services/cnd.service";
import { ICNDDataResponse, ICNDListData, IDeleteResponse } from "../../../../_services/interface/cnd.interface";

interface IDeleteCndModalProps {
  guid_client: string;
  cnd: ICNDDataResponse;
  certificate: ICNDListData;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
  isManual: boolean;
}

export const DeleteCndModal = (props: IDeleteCndModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const toast = useToast();

  const yesOption = async () => {
    setButtonDisabled(true);
    const { response, status } = await deleteCND(props.cnd.guid_cnd, props.certificate.guid_certificate, props.guid_client);

    if (status && status === 200) {
      toast({
        title: 'Certidão Removido com sucesso',
        description: `A certidão foi removida com sucesso`,
        status: 'success',
        isClosable: true,
        duration: 3000,
      });
      props.flushHook(true);
      if ((response as IDeleteResponse).closeModal) props.closeModal(); 
    } else {
      toast({
        title: 'Falha ao remover a Certidão',
        description: `Algum erro ocorreu ao tentar remover a Certidão, tente novamente mais tarde.`,
        status: 'error',
        isClosable: true,
        duration: 3000,
      });
    }
    setButtonDisabled(false);
    onClose();
  };

  return (
    props.isManual ? (
      <>
        <Tooltip label="Deletar certidão">
          <Flex className="icon-bg-blue">
              <Link color="white" onClick={onOpen} target="_blank" alignItems="center" justifyContent="center" display="flex" width="30px" height="30px" minWidth="30px" minHeight="30px">
                <Image height="20px" width="20px" src="/icons/trash_with_backgroud.svg" resize="none" _hover={{ cursor: "pointer" }}/>
              </Link>
          </Flex>
        </Tooltip>
  
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
          <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
            <ModalHeader fontFamily="Poppins-SemiBold">Deletar Certidão</ModalHeader>
            <ModalCloseButton />
            <ModalBody fontFamily="Poppins-medium" fontSize={12}>
              <Flex justifyContent="center" flexDirection="column" gap={5}>
                <Text>
                  Deseja remover a certidão? Esta opção não pode ser desfeita.
                </Text>
  
                <Flex gap={2} flexGrow={1} justifyContent="space-between">
                  <FormButton onClick={yesOption} disabled={isButtonDisabled} width="50%">Sim</FormButton>
                  <FormButton onClick={onClose} disabled={isButtonDisabled} width="50%">Não</FormButton>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    ) : null
  );
}