import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DashInput } from "../pages/dashboard/Users/components/DashInput";
import { useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, ModalFooter, Image, Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FormButton } from "../components/login/button.component";
import useLoginFormStore from "../store/useLoginFormStore";
import { generate2fa, validate2fa } from "../_services/twoFactorAuthentication.service";
import { IResponse } from "../_services/interface/user.interface";
import { useEffect, useState } from "react";

interface I2faValidationProps {
  callback: (code: string) => void;
  disclosureProps: ReturnType<typeof useDisclosure>;
}

interface IForm {
    code: string;
}

export const Validation2fa = (props: I2faValidationProps) => {
    const { validate } = useLoginFormStore();

    const { isOpen, onOpen, onClose } = props.disclosureProps;

    const YupChangeForm = Yup.object().shape({
        code: Yup.string().required("Código de Verificação é obrigatório"),
    });
    const resolverForm = { resolver: yupResolver(YupChangeForm) };
    const { formState: { errors }, register, reset, handleSubmit } = useForm<IForm>(resolverForm);

    const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);
    const [sendingMail, setSendingMail] = useState<boolean>(false);
    const [mailSent, setMailSent] = useState<boolean>(false);
    const [validatingCode, setValidatingCode] = useState<boolean>(false);
    const [codeValidated, setCodeValidated] = useState<boolean>(false);

    const user = validate as IResponse;

    const handleFormInfo = async (data: IForm) => {
        setValidatingCode(true);
        await validateCode(data.code);
        setValidatingCode(false);
    };

    const validateCode = async (code: string) => {
        const cleanCode = code.replace(/\D/g, '');
        const { status, response } = await validate2fa(user.guid_user, cleanCode);
        if(status === 200 && response) {
            setCodeValidated(true);

            await new Promise(resolve => setTimeout(resolve, 1000));
            
            props.callback(code);
            onClose();
        } else {
            setIsInvalidCode(true);
        }
    }

    const obscureEmail = (email: string) => {
        var parts = email.split('@');
        var username = parts[0];
        var domain = parts[1];
        
        var firstTwo = username.substring(0, 2);
        var lastTwo = username.substring(username.length - 2);
        var obscuredUsername = firstTwo + '***' + lastTwo;

        return obscuredUsername + '@' + domain;
    }

    const sendCodeByEmail = async () => {
        setMailSent(false);
        setSendingMail(true);

        const { status } = await generate2fa(user.guid_user);
        if(status === 200) {
            setMailSent(true);
        }

        setSendingMail(false);
    }

    useEffect(() => {
        if (isOpen) {
            reset();
            sendCodeByEmail();
            setIsInvalidCode(false);
            setCodeValidated(false);
            setMailSent(false);
        }
    }, [isOpen]);

    return (
        <>
            <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
                
                <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
                    <ModalHeader fontFamily="Poppins-medium">Acesso Certificado Digital</ModalHeader>

                    <ModalCloseButton />

                    <ModalBody fontFamily="Poppins-medium" fontSize={14} mb={8} color="#171923">
                        <Text fontSize={14}>
                            <strong>Atenção! Esta página contém dados sensíveis que exigem autenticação.</strong>
                        </Text>

                        <Text fontSize={14} mb={3}>
                            O acesso é restrito para garantir a segurança e privacidade dos dados.
                        </Text>

                        <Text fontSize={12} mb={6}>
                            Por favor, insira o código de 6 dígitos enviado para <strong>[{obscureEmail(user.email)}]</strong> para criar um novo certificado digital.
                        </Text>

                        <Text fontSize={12}>
                            Código de verificação
                        </Text>

                        <DashInput 
                            type="text" 
                            placeholder="Seu código de segurança" {...register("code")} 
                            border="1px solid #CBD5E0"
                            borderTop="1px solid #CBD5E0"
                            borderRight="1px solid #CBD5E0"
                            borderLeft="1px solid #CBD5E0"
                            borderBottom="1px solid #CBD5E0"
                            borderRadius={4}
                        />

                        <Flex>
                            {
                                isInvalidCode ? 
                                    <Flex mt="5px">
                                        <Image src="/icons/icon-close-red.svg" alt="Close Red" marginRight="3px" />
                                        <Text
                                            fontSize={12}
                                            color="#DC2626"
                                        >
                                            Código invalido.
                                        </Text>
                                    </Flex>
                                : null
                            }

                            {
                                codeValidated ? 
                                    <Flex mt="5px">
                                        <Image src="/icons/check-green.svg" alt="Check Green" marginRight="3px" />
                                        <Text
                                            fontSize={12}
                                            color="#059669"
                                        >
                                            Código validado.
                                        </Text>
                                    </Flex>
                                : null
                            }

                            {
                                sendingMail ? 
                                    <Flex mt="5px">
                                        <Image src="/icons/loading-blue.svg" className="rotate-forever" alt="Loading" marginRight="3px" />
                                        <Text
                                            fontSize={12}
                                            color="#4B4EFF"
                                        >
                                            Enviando código...
                                        </Text>
                                    </Flex>
                                : null
                            }

                        {
                            mailSent && !codeValidated ? 
                                <Flex mt="5px">
                                    <Image src="/icons/check-green.svg" alt="Check Green" marginRight="3px" />
                                    <Text
                                        fontSize={12}
                                        color="#059669"
                                    >
                                        Código enviado por email.
                                    </Text>
                                </Flex>
                            : null
                        }
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Text 
                            color="#171923" 
                            fontSize="14px" 
                            fontWeight="500"
                            marginRight="45px"
                            cursor="pointer"
                            onClick={onClose}
                        >
                            Fechar
                        </Text>
                        <FormButton 
                            onClick={handleSubmit(handleFormInfo)}
                            isLoading={validatingCode || codeValidated}
                            disabled={validatingCode || codeValidated}
                        >
                            Acessar
                        </FormButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}