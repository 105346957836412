import { AttachmentIcon, CalendarIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Image, Text, Tooltip } from "@chakra-ui/react"
import moment from "moment";
import { UserIcon } from "../../Users/icons/UserIcon";
import { ICardList } from "../interfaces/ICardList";
import { cutText } from "../utils/kanban.functions";
import { AlertTriangle } from "../Icons/AlertTriangle";
import { useState } from "react";
import { EditScheduleModal } from "../modals/EditScheduleModal";
import { EDisplay } from "../interfaces/IObligation";
import { EPriority } from "../../../../_services/enum/irs.enum";
import { jurisdictionTag } from "../../../../utils/utils.functions";

interface IKanbanElementProps {
  onClick: () => void;
  card: ICardList;
  color: string;
  isFinished: boolean;
  guid_client: string;
}

export const KanbanElement = (props: IKanbanElementProps) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshData, setRefreshData] = useState<boolean>(false);
  
  const dateColor = () => {
    return props.isFinished ? 'black' : moment.utc().diff(moment.utc(props.card.dueDate), 'd') > 0 ? 'red' : 'black';
  }

  const isToday = (card: ICardList) => moment.utc(card.dueDate).startOf('day').isSame(moment.utc().startOf('day'));

  const isCompleted = (companies: any[]) => {
    let completed = true;
    companies.forEach(_c => {
      if (_c.status !== 'concluded') {
        completed = false;
      }
    });
    return completed;
  }

  const recurrencyLabel = {
    DAY: 'Diário',
    WEEK: 'Semanalmente',
    MONTH: 'Mensal',
    YEAR: 'Anual',
  }

  const priorityOptions = [
    { value: EPriority.URGENT, label: 'Urgente', icon: <Image w="22px" h="22px" src="/icons/flags/red.svg" /> },
    { value: EPriority.HIGH, label: 'Alta', icon: <Image w="22px" h="22px" src="/icons/flags/orange.svg" /> },
    { value: EPriority.REGULAR, label: 'Normal', icon: <Image w="22px" h="22px" src="/icons/flags/blue.svg" /> },
    { value: EPriority.LOW, label: 'Baixa', icon: <Image w="22px" h="22px" src="/icons/flags/gray.svg" /> },
  ];

  const priority = priorityOptions.find((x) => x.value === props.card.priority);

  return (
    <Flex border="1px solid" borderColor="gray.400" fontFamily="Poppins-Medium" fontSize="12px"  width="100%" borderRadius="5px" p={2} bg="white" _hover={{ cursor: "pointer", background: "gray.100" }}>
      <Flex direction="column" gap={2} flexGrow={1}>
        <Flex display="contents" onClick={props.onClick}>
          <Flex justifyContent="space-between" gap={2}>
            <Flex alignItems="center" gap="4px">
              <Image src="/icons/calender.svg" w="16px" />
              <Text fontWeight="bold" color={dateColor()}>
                {moment.utc(props.card.dueDate).format('DD/MM/YYYY')}
              </Text>
              {isToday(props.card) && !isCompleted(props.card.companies) ? (
                <Tooltip label="Vence hoje">
                  <Flex marginRight="15px" alignItems="center" color="red" gap={0}>
                    <AlertTriangle />
                  </Flex>
                </Tooltip>
              ) : ('')}
            </Flex>
            <Flex>
              {jurisdictionTag(props.card.jurisdiction)}
            </Flex>
          </Flex>
          <Text textAlign="left">{cutText(props.card.name, 100)}</Text>
        </Flex>

        <Divider />

        <Flex alignItems="center">
          {
            props.card.guid_card_structure ? 
            (
              <Flex justifyContent="space-between" w="100%" p="0 15px">
                { priority && (<>
                  <Flex>
                    <Box as="span" mr="2">{priority.icon}</Box>
                    {priority.label}
                  </Flex>

                  {
                    props.card.has_recurrency
                    && props.card.schedule.frequencyType
                    && props.card.schedule.frequencyUnit
                    && (
                      <Flex alignItems="center">
                        <Image src="icons/repeat.svg" w="16px" mr="5px"/>
                        {recurrencyLabel[props.card.schedule.frequencyType]}
                      </Flex>
                    )
                  }
                </>)}
              </Flex>
            ) : (
              <EditScheduleModal icon={true} flushHook={setRefreshData} ruleName={props.card.schedule.rulename} display={EDisplay.ruleName} guid_client={props.guid_client} guid_schedule={props.card.schedule.guid_schedule} />
            )
          }
        </Flex>

        <Divider />

        <Flex alignItems="center" justifyContent="space-around" gap={2} onClick={props.onClick}>
          <Tooltip label={props.card.schedule.cardStructureType === 'CERTIFICATE_COMPANY' ? 'Certidões Associadas' : 'Empresas Associadas'}>
            <Flex alignItems="center" gap={2}>
              <Image w="24px" src={props.card.schedule.cardStructureType === 'CERTIFICATE_COMPANY' ? '/icons/attachments.svg' : '/icons/offices.svg'} />
              <Text fontWeight="bold">{props.card.companies.length}</Text>
            </Flex>
          </Tooltip>

          <Tooltip 
            label={
              <Box>
                {props.card.responsibles.map((responsible) => (
                  <Text key={responsible.guid_user}>{responsible.name}</Text>
                ))}
              </Box>
            }
          >
            <Flex alignItems="center" gap={2}>
              <UserIcon />
              <Text fontWeight="bold">{props.card.responsibles.length}</Text>
            </Flex>
          </Tooltip>

          <Tooltip label="Comentários">
            <Flex alignItems="center" gap={2}>
              <Image w="24px" src="/icons/comment.svg" />
              <Text fontWeight="bold">{props.card.commentsCount}</Text>
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
}