import { Flex, Image, Text, Toast, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FilterDataSelected, Filters, FilterTypeEnum } from "../../../components/Filters";
import { useClientContext } from "../../../components/sidebar";
import { Datatables } from "../../../components/Table/Datatables";
import { IAction, IColumn, IData, ITableMetaData } from "../../../components/Table/table.interface";
import { validFilterField } from "../../../components/Table/utils";
import useLoginFormStore from "../../../store/useLoginFormStore";
import { listDigitalCertificate } from "../../../_services/digitalCertificate.service";
import { IDigitalCertificate, IFilterDigitalCertificate } from "../../../_services/interface/digitalCertificate.interface";
import { IResponse } from "../../../_services/interface/user.interface";
import { checkCollaboratorPermission } from "../Users/utils/checkPermission";
import { ActiveDigitalCertificateModal } from "./modals/ActiveDigitalCertificateModal";
import { CreateDigitalCertificateModal } from "./modals/CreateDigitalCertificateModal";
import { DeleteDigitalCertificateModal } from "./modals/DeleteDigitalCertificateModal";
import { DisplayRelationsCompanies } from "./modals/DisplayRelationsCompanies";

interface IDigitalCertificatePanelProps {
  
}

export const DigitalCertificatePanel = (props: IDigitalCertificatePanelProps) => {
  const { validate } = useLoginFormStore();
  const { guid_client } = useClientContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [digitalCertificateMetaData, setDigitalCertificateMetaData] = useState<ITableMetaData|undefined>();
  const [digitalCertificateList, setDigitalCertificateList] = useState<IData<any>|null>(null);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const [currentCompany, setCurrentCompany] = useState<string|null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenRelationCompanies, setIsOpenRelationCompanies] = useState<boolean>(false);
  const useDisclosureDeleteModal = useDisclosure();
  const useDisclosureActiveModal = useDisclosure();
  const [selectedDigitalCertificate, setSelectedDigitalCertificate] = useState<IDigitalCertificate|null>(null);

  // Filter
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const getDigitalCertificateData = async () => {
    if (guid_client) {
      setIsLoading(true);
  
      const filters = getFilter();
      const { status, response } = await listDigitalCertificate(guid_client, filters);
      if (status === 200 && 'meta' in response && 'data' in response) {
        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          setDigitalCertificateList(response);
        }
      } else {
        Toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar obter as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }

  const getFilter = (): IFilterDigitalCertificate => {
    const companies: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.digitalCertificateCompanyFilter] : [];
    const fileNames: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.digitalCertificateFileNameFilter] : [];

    const startIssuedAt: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.startIssuedAtFilter][0] : undefined;
    const endIssuedAt: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.endIssuedAtFilter][0] : undefined;

    const startValidUntil: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.startValidUntilFilter][0] : undefined;
    const endValidUntil: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.endValidUntilFilter][0] : undefined;

    const filters: IFilterDigitalCertificate = {
      page: digitalCertificateMetaData?.currentPage ?? 1,
      row_by_page: digitalCertificateMetaData?.rowByPage ?? 10,
      order_field: digitalCertificateMetaData?.orderField ?? 'createdAt',
      order_direction: digitalCertificateMetaData?.orderDirection ?? 'desc',

      ...(validFilterField(startIssuedAt) ? { initial_issued_at_date: startIssuedAt } : {}),
      ...(validFilterField(endIssuedAt) ? { final_issued_at_date: endIssuedAt } : {}),

      ...(validFilterField(startValidUntil) ? { initial_valid_until_date: startValidUntil } : {}),
      ...(validFilterField(endValidUntil) ? { final_valid_until_date: endValidUntil } : {}),

      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(fileNames) ? { fileNames: fileNames.join(',') } : {}),
    };

    return filters;
  }

  useEffect(() => {
    if (guid_client) {
      setRefreshTable(true);
    }
  }, [guid_client, digitalCertificateMetaData, filterDataSelected])

  useEffect(() => {
    if (refreshTable) {
      getDigitalCertificateData();
      setRefreshTable(false);
      setCurrentCompany(null);
      setSelectedDigitalCertificate(null);
    }
  }, [refreshTable])

  const columns = [
    {
      title: 'Nome',
      name: 'name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Titular',
      name: 'companyOwner.displayName',
      sortable: false,
      align: 'center',
    },
    {
      title: 'CNPJ',
      name: 'companyOwner.formattedCnpj',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Empresas Vinculadas',
      name: 'companiesCount',
      sortable: false,
      align: 'center',
      hyperlink: (row: IDigitalCertificate) => {
        setSelectedDigitalCertificate(row);
        setIsOpenRelationCompanies(true);
      }
    },
    {
      title: 'Data de emissão',
      name: 'issued_date_formated',
      orderFieldName: 'issued_date',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Data de validade',
      name: 'expiration_date_formated',
      orderFieldName: 'expiration_date',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'is_active',
      sortable: true,
      align: 'center',
      tags: [
        {
          value: true,
          color: '#DCFCE7',
          text: 'Ativo',
          textColor: 'black'
        },
        {
          value: false,
          color: '#FEE2E2',
          text: 'Desativado',
          textColor: 'black'
        }
      ]
    },
  ] as IColumn[];

  const actions = [
    {
      text: 'Editar',
      icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
      isVisible: (digitalCertificate: IDigitalCertificate): boolean => {return true},
      isDisabled: (digitalCertificate: IDigitalCertificate): boolean => {return !guid_client},
      action: (digitalCertificate: IDigitalCertificate) => {
        setCurrentCompany(digitalCertificate.companyOwner.guid_company);
        setIsOpen(true);
      }
    },
    {
      text: (digitalCertificate: IDigitalCertificate) => digitalCertificate.is_active ? 'Desativar' : 'Ativar',
      icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
      isVisible: (digitalCertificate: IDigitalCertificate): boolean => {return true},
      isDisabled: (digitalCertificate: IDigitalCertificate): boolean => {return !guid_client},
      action: (digitalCertificate: IDigitalCertificate) => {
        setSelectedDigitalCertificate(digitalCertificate);
        useDisclosureActiveModal.onOpen();
      }
    },
    {
      text: 'Deletar',
      icon: <Image src="/icons/trash_black.svg" width={5} height="auto" />,
      isVisible: (digitalCertificate: IDigitalCertificate): boolean => {return true},
      isDisabled: (digitalCertificate: IDigitalCertificate): boolean => {return !guid_client},
      action: (digitalCertificate: IDigitalCertificate) => {
        setSelectedDigitalCertificate(digitalCertificate);
        useDisclosureDeleteModal.onOpen();
      }
    },
  ] as IAction[];

  const taxCurrentAccountFilter = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.digitalCertificateCompanyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.digitalCertificateFileNameFilter,
          position: 2,
          size: 6,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.startIssuedAtFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.endIssuedAtFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.startValidUntilFilter,
          position: 3,
          size: 3,
        },
        {
          name: FilterTypeEnum.endValidUntilFilter,
          position: 4,
          size: 3,
        },
      ],
      position: 2
    },
  ]

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" mr="30px" mt="10px">
      <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4} mb="15px">
        <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">Certificado Digital</Text>

        {!isLoading && guid_client && checkCollaboratorPermission(validate as IResponse, guid_client, 'org.owner') ? (
          <Flex alignItems='center' gap={4}>
            <CreateDigitalCertificateModal
              setRefreshTable={setRefreshTable}
              guid_company={currentCompany}
              setGuidCompany={setCurrentCompany}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
          </Flex>
        ) : null}
      </Flex>

      <Flex mt={12} direction="column">
        <Flex mt={4}>
          <Filters
            filters={taxCurrentAccountFilter}
            selectedValuesHook={setFilterDataSelected}
          /> 
        </Flex>

        <Flex mt={12} direction="column">
          <Datatables
            name="Certificado(s) Cadastrado(s)"
            columns={columns}
            actions={actions}
            metaDataHook={setDigitalCertificateMetaData}
            data={digitalCertificateList}
            isLoading={isLoading}
            showTotalRows={true}
            textTotalRows="Certificado(s) encontrado(s)"
          />
        </Flex>
      </Flex>
    </Flex>

    <DisplayRelationsCompanies
      isOpen={isOpenRelationCompanies}
      setIsOpen={setIsOpenRelationCompanies}
      digitalCertificate={selectedDigitalCertificate}
    />

    {
      guid_client && selectedDigitalCertificate ? (
        <DeleteDigitalCertificateModal
          guid_client={guid_client}
          digitalCertificate={selectedDigitalCertificate}
          disclosureProps={useDisclosureDeleteModal}
          setRefreshTable={setRefreshTable}
        />
      ) : null
    }

    {
      guid_client && selectedDigitalCertificate ? (
        <ActiveDigitalCertificateModal
          guid_client={guid_client}
          digitalCertificate={selectedDigitalCertificate}
          disclosureProps={useDisclosureActiveModal}
          setRefreshTable={setRefreshTable}
        />
      ) : null
    }
    
  </>)
};