import { IDataMeta } from "../../components/Table/table.interface";
import { ICertificate } from "./certificate.interface";
import { ICNDDataResponse, ICNDListDataEntity } from "./cnd.interface";
import { ITaxCurrentAccount } from "./taxCurrentAccount.interface";

export interface IGetBulkUpdateResponse {
  guid_bulk_update: string;
  guid_client: string;
  type: string;
  items: IBulkItemCertificateResponse;
  isPending?: boolean;
  can_retry?: boolean;
}

export interface IBulkItem {
  guid_bulk_update: string;
  status: BulkUpdateItemCertificateStatus;
  totalItems: number;
  certificate: ICertificate;
  entity: ICNDListDataEntity;
  region: {
    state: null|string;
    city: null|string;
  }
}

export interface IBulkItemCertificate extends IBulkItem {
  guid_bulk_item_certificate: string;
  cnd?: ICNDDataResponse;
}

export interface IBulkItemTaxCurrentAccount extends IBulkItem {
  guid_bulk_item_tax_current_account: string;
  taxCurrentAccount?: ITaxCurrentAccount;
}

export interface IBulkItemCertificateResponse {
  data: IBulkItemCertificate[];
  meta: IDataMeta;
}

export enum BulkUpdateItemCertificateStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED', // When do not search the certificate
  ERROR = 'ERROR', // When search the certificate and return an error
  SUCCESS = 'SUCCESS',
}

export interface IFilterListBulkUpdate {
  page: number;
  row_by_page: number;

  order_field?: string;
  order_direction?: string;
}
