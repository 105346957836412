import { ArrowLeftIcon, ArrowUpDownIcon } from "@chakra-ui/icons";
import { useDisclosure, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Text, Checkbox, Link } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import LoadingComponent from "../../../../components/loading";
import { InputFilter } from "../../../../components/SelectFilter";
import { ICollaborators, IGetEmployeesResponse } from "../../../../_services/interface/company.interface";
import { UserIcon } from "../icons/UserIcon";
import { getClientEmployees } from "../../../../_services/client.service";

interface ISelectCollaboratorModalProps {
  guid_client: string;
  bigSelector?: boolean;
  width?: number | string;
  callback: (collaborator: string[]) => void;
  isMulti?: boolean;
  selected?: string[];
  label?: string;
  selection?: string;
  openModal?: boolean;
  disabled?: boolean; 
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectCollaboratorModal = (props: ISelectCollaboratorModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [collaborators, setCollaborators] = useState<ICollaborators[]>([]);
  const [filteredCollab, setFilteredCollab] = useState<ICollaborators[]>([]);
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [alreadySelected, setAlreadySelected] = useState<string[]>(props.selected ? props.selected : []);
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [alphaOrder, setAlphaOrder] = useState<boolean>(false);
  const [collaboratorChecked, setCollaboratorChecked] = useState<string[]>(props.selected ? props.selected : []);
  const toast = useToast();

  useEffect(() => {
    if(props.openModalHook && props.openModal) {
      onOpen();

      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  const listEmployees = async () => {
    setLoadingState(true);
    const { status, response } = await getClientEmployees(props.guid_client);
    if (status === 200) {
      setCollaborators((response as IGetEmployeesResponse).collaborators);
    } else {
      toast({
        title: 'Falha ao retornar a lista de usuários',
        description: 'Algum erro ocorreu ao tentar retornar a lista de funcionários desta empresa, tente novamente mais tarde.',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    setLoadingState(false);
  }

  useEffect(() => {
    if (isOpen) {
      if (collaborators.length === 0) {
        listEmployees();
      }

      setCollaboratorChecked(props.selected ?? []);
      setAlreadySelected(props.selected ?? []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (filterSearch) {
      setFilteredCollab(
        collaborators.filter((_x) => _x.users.isActive && _x.users.isValidated && !_x.users.isBlocked).filter((x) => 
          x.users.name.toLowerCase().includes(filterSearch.toLowerCase()) || 
          x.users.email.toLowerCase().includes(filterSearch.toLowerCase())
        )
      );
    } else {
      setFilteredCollab(collaborators);
    }
  }, [filterSearch, collaborators]);

  useEffect(() => {
    setCollaboratorChecked(props.selected ?? []);
    setAlreadySelected(props.selected ?? []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected]);

  const sortCompanies = (type: string) => {
    const data = [...filteredCollab];
    setAlphaOrder(!alphaOrder);

    if (type === "email") {
      data.sort((a, b) => {
        if (alphaOrder) {
          return a.users.email! < b.users.email! ? -1 : a.users.email! > b.users.email! ? 1 : 0;
        } else {
          return a.users.email! > b.users.email! ? -1 : a.users.email! < b.users.email! ? 1 : 0;
        }
      });
    }

    if (type === "name") {
      data.sort((a, b) => {
        if (alphaOrder) {
          return a.users.name! < b.users.name! ? -1 : a.users.name! > b.users.name! ? 1 : 0;
        } else {
          return a.users.name! > b.users.name! ? -1 : a.users.name! < b.users.name! ? 1 : 0;
        }
      });
    }

    setFilteredCollab(data);
  }

  const handleCollaboratorCheck = (e: { target: { value: string, checked: boolean } }) => {
    var updatedList = [...collaboratorChecked];
    if (e.target.checked) {
      updatedList = [...collaboratorChecked, e.target.value];
    } else {
      updatedList.splice(collaboratorChecked.indexOf(e.target.value), 1);
    }
    setCollaboratorChecked(updatedList);
  }

  const selectUniquePerson = (guid_collaborator: string) => {
    //Set the user
    props.callback([guid_collaborator]);
    //Close the modal
    onClose();
  }

  const sendMultiperson = () => {
    props.callback(collaboratorChecked);
    //Close the modal
    onClose();
  }

  return (
    <>
      {!props.openModalHook ? (
        props.bigSelector ? (
          <Flex border="1px solid" fontSize="12px" width="auto" w={props.width ? props.width : "100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
            <Link color="#4B4EFF" fontWeight="bold" onClick={onOpen}>{alreadySelected.length > 0 ? `${alreadySelected.length} ${props.selection}` : props.label}</Link>
          </Flex>
        ) : (
          <Flex bgColor="#4B4EFF" fontSize="12px" p={1} borderRadius={5} color="white" onClick={onOpen} alignItems="center" justifyContent="center" _hover={{ cursor: "pointer" }}>
            <UserIcon color="white" />&nbsp;{props.selected ? props.selected.length : 0}
          </Flex>
        )
      ) : null}
      
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Adicionar Responsáveis</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12} mb={8}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>Selecione os responsáveis para este card</Text>

              <Flex direction="column">
                {isLoading ? <LoadingComponent /> : (
                  <>
                    <Flex>
                      <InputFilter value={filterSearch} onChange={(e: any) => setFilterSearch(e.target.value)} placeholder="Busque pelo o Nome ou Email" />
                    </Flex>
                    <TableContainer maxHeight={300} overflowX="auto" overflowY="auto">
                      <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                        <Thead>
                          <Tr>
                            <Th width={20} textTransform="none" fontFamily="Poppins-Medium"></Th>
                            <Th textAlign="center" textTransform="none" fontFamily="Poppins-Medium" onClick={() => sortCompanies('name')} _hover ={{cursor:"pointer"}}>Nome <ArrowUpDownIcon /></Th>
                            <Th textAlign="center" textTransform="none" fontFamily="Poppins-Medium" onClick={() => sortCompanies('email')} _hover ={{cursor:"pointer"}}>Email <ArrowUpDownIcon /></Th>
                          </Tr>
                        </Thead>
                        <Tbody alignItems="center" justifyContent="center">
                          {filteredCollab
                            .filter((_x) => _x.users.isActive && _x.users.isValidated && !_x.users.isBlocked)
                            //.filter((_x) => !alreadySelected.includes(_x.guid_collaborator))
                            .map((_el, _index) => (
                              <Tr key={_index}>
                                <Td>
                                  {props.isMulti ? (
                                    <Checkbox size="md" colorScheme="green" isChecked={collaboratorChecked.includes(_el.guid_collaborator)} onChange={handleCollaboratorCheck} value={_el.guid_collaborator} />
                                  ) : (
                                    <Flex width="26px" bgColor="#4B4EFF" alignItems="center" justifyContent="center" color="white" borderRadius={5} height="26px" resize="none" onClick={() => selectUniquePerson(_el.guid_collaborator)} _hover={{ cursor: "pointer" }}>
                                      <ArrowLeftIcon />
                                    </Flex>
                                  )}
                                </Td>
                                <Td textAlign="center">{_el.users.name}</Td>
                                <Td textAlign="center">{_el.users.email}</Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Flex>
              {props.isMulti ? (
                <Flex direction="row" justifyContent="center" alignItems="stretch" gap={2}>
                  <Button
                    bgColor="#4B4EFF"
                    color="white"
                    width="70%"
                    height={37}
                    _hover={{ bg: '#282be0' }}
                    onClick={sendMultiperson}
                    fontSize={'14px'}
                    fontFamily={'Poppins-Medium'}
                    fontWeight={400}
                    disabled={props.disabled}
                    isDisabled={props.disabled}
                  >
                    Salvar alteração
                  </Button>
                </Flex>
              ) : null}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}