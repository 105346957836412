import { IStatus } from "../../../../_services/interface/certificate.interface";
import { ITaxCurrentAccountListData } from "../../../../_services/interface/taxCurrentAccount.interface";
import { ITag, ITagMultiTootlip } from "../../../../components/Table/table.interface";
import { BulkUpdateItemCertificateStatus, IBulkItemTaxCurrentAccount } from "../../../../_services/interface/bulk-update.interface";

export const taxCurrentAccountStatusForTagList = (taxCurrentAccount: ITaxCurrentAccountListData) => {
  const status = taxCurrentAccount.status as IStatus;

  if(!status) {
    return {
      text: "-",
      color: "#000",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  let tooltip = {};
  const tooltipIcon: ITagMultiTootlip[] = [];

  if(status.tooltip_icon) {
    tooltipIcon.push({
      tootltipIconText: status.tooltip_text as string,
      tootltipIconUrl: `../icons/${status.tooltip_icon}.svg`
    })
  }

  if (taxCurrentAccount.errorMessage) {
    tooltipIcon.push({
      tootltipIconText: taxCurrentAccount.errorMessage,
      tootltipIconUrl: "/icons/alert.svg"
    })
  }

  return {
    text: status.name,
    color: status.color,
    textColor: status.textColor ?? "#000",
    minWidth: "118px",
    tooltip: tooltipIcon,
  } as ITag;
};

export const taxCurrentAccountCertificateStatusForTagList = (taxCurrentAccount: ITaxCurrentAccountListData) => {
  if(!taxCurrentAccount.certificate?.status) {
    return {
      text: "-",
      color: "#00000000",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  const status = taxCurrentAccount.certificate.status as IStatus;

  let tooltip = {};
  const tooltipIcon: ITagMultiTootlip[] = [];

  if(status.tooltip_icon) {
    tooltipIcon.push({
      tootltipIconText: status.tooltip_text as string,
      tootltipIconUrl: `../icons/${status.tooltip_icon}.svg`
    })
  }

  if (taxCurrentAccount.certificate.error_message) {
    tooltipIcon.push({
      tootltipIconText: taxCurrentAccount.certificate.error_message,
      tootltipIconUrl: "/icons/alert.svg"
    })
  }

  return {
    text: status.name,
    color: status.color,
    textColor: status.textColor ?? "#000",
    minWidth: "118px",
    tooltip: tooltipIcon,
  } as ITag;
};

export const statusForTagListUpdate = (bulkItem: IBulkItemTaxCurrentAccount) => {
  let tooltip = {};

  const entity = bulkItem.entity.type === 3 ? 'Pessoa Fisica' : "Empresa";

  if (bulkItem.status === BulkUpdateItemCertificateStatus.PENDING) {
    tooltip = {
      tootltipText: 'A pesquisa está sendo realizada pela taxly. Em breve traremos um retorno sobre essa consulta. Por favor aguarde.',
      iconUrl: `../icons/information-circle-blue.svg`
    }
    return {
      text: "Atualizando...",
      color: "#DDE5FF",
      textColor: "#000",
      minWidth: "118px",
      ...tooltip
    } as ITag
  }

  if(!bulkItem.taxCurrentAccount) {
    return {
      text: "Erro",
      color: "#FEF9C3",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  const taxCurrentAccount = bulkItem.taxCurrentAccount;
  const status = taxCurrentAccount.status as IStatus;

  if(!status) {
    return {
      text: "Erro",
      color: "#FEF9C3",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  if(status.tooltip_icon) {
    tooltip = {
      tootltipText: status.tooltip_text,
      iconUrl: `../icons/${status.tooltip_icon}.svg`
    }
  }

  return {
      text: status.name,
      color: status.color,
      textColor: "#000",
      minWidth: "118px",
      ...tooltip
  } as ITag
}