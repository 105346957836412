import { ApiCall, ApiAuth } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { IResponsibleFilterOptionsResponse, ICertificateResponsibleList, IVinculatedDataPost, IQueryListResponsibleCertificate } from "./interface/responsible.interface";

export const getResponsibleCertificateFilterOptions = async (guid_client: string): Promise<TPremiseResponse<IResponsibleFilterOptionsResponse>> => {
  return await new ApiCall(`/filter/${guid_client}/responsibles-certificate/0`, ApiAuth()).get<IResponsibleFilterOptionsResponse>();
};

export const getResponsibleTaxCurrentAccountFilterOptions = async (guid_client: string): Promise<TPremiseResponse<IResponsibleFilterOptionsResponse>> => {
  return await new ApiCall(`/filter/${guid_client}/responsibles-tax-current-account/0`, ApiAuth()).get<IResponsibleFilterOptionsResponse>();
};

export const getCertificateList = async (guid_client: string, guid_collaborator: string, filters: IQueryListResponsibleCertificate): Promise<TPremiseResponse<ICertificateResponsibleList>> => {
  return await new ApiCall(`/responsible/${guid_client}/list/certificate/${guid_collaborator}`, ApiAuth()).post<ICertificateResponsibleList, IQueryListResponsibleCertificate>(filters);
}

export const postCertificatesVinculated = async (guid_client: string, guid_collaborator: string, data: IVinculatedDataPost): Promise<TPremiseResponse<ICertificateResponsibleList>> => {
  return await new ApiCall(`/responsible/${guid_client}/vinculate/certificate/${guid_collaborator}`, ApiAuth()).post<ICertificateResponsibleList, IVinculatedDataPost>(data);
}
