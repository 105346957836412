import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  Image,
  ModalFooter,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import {
  FilterDataSelected,
  FilterRow,
  Filters,
  FilterTypeEnum,
} from '../../../../components/Filters';
import { Datatables } from '../../../../components/Table/Datatables';
import { FormButton } from '../../../../components/login/button.component';
import { RetrieveCertificatesStyle } from '../../../../styles/components/RetrieveCertificates';
import { IResponse, IUser } from '../../../../_services/interface/user.interface';
import { getCertificateList, postCertificatesVinculated } from '../../../../_services/responsible.service';
import {
  ICertificateResponsibleListData,
  IVinculatedDataPost,
  IQueryListResponsibleCertificate,
} from '../../../../_services/interface/responsible.interface';
import {
  IColumn,
  IData,
  IDataMeta,
  ITableMetaData,
} from '../../../../components/Table/table.interface';
import { stateRegionForTag } from '../../../../components/Table/utils';
import { debounce, set } from 'lodash';
import { checkCollaboratorPermission } from '../utils/checkPermission';

interface IVinculateCertificateModalProps {
  user: IUser | undefined;
  guid_client: string;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
  validation: IResponse;
}

export const VinculateCertificateModal = (
  props: IVinculateCertificateModalProps
) => {
  // Toast
  const toast = useToast();
  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Filter
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected | null>(null);

  const [guidCollaborator, setGuidCollaborator] = useState<string | null>(null);

  // Data
  const [certificateResponsiblesData, setCertificateResponsiblesData] = useState<IData<any> | null>(null);
  const [certificateResponsiblesDataAll, setCertificateResponsiblesDataAll] = useState<ICertificateResponsibleListData[]>([]);
  const [certificateGuidAll, setCertificateGuidAll] = useState<string[]>([]);
  const [certificateResponsiblesSelected, setCertificateResponsiblesSelected] = useState<string[]>([]);
  const [certificateResponsiblesSelectedList, setCertificateResponsiblesSelectedList] = useState<IData<any>|null>(null);;
  const [certificateResponsiblesSelectedMeta, setCertificateResponsiblesSelectedMeta] = useState<ITableMetaData|undefined>();

  const [metaData, setMetaData] = useState<ITableMetaData | undefined>();

  const [selectedFromBackend, setSelectecFromBackend] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [isLoadingButton, setLoadingButton] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [showOnlySelecteds, setShowOnlySelecteds] = useState<boolean>(false);

  const canEdit = checkCollaboratorPermission(props.validation, props.guid_client, 'responsibles.write')

  useEffect(() => {
    if (props.user) {
      const guid_collaborator = props.user.organizations.find(
        (o) => o.guid_client === props.guid_client
      )?.guid_collaborator;

      setGuidCollaborator(guid_collaborator ?? null);
    }
  }, [props.user, props.guid_client])

  useEffect(() => {
    if (props.openModal) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    if (refreshData) {
      getCertificatesToVinculate();
      setRefreshData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    if (isOpen) {
      setSelectecFromBackend(true);
      debouncedRefreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const debouncedRefreshData = useCallback(debounce(() => setRefreshData(true), 400), []);
  useEffect(() => {
    debouncedRefreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metaData,
    filterDataSelected,
  ]);

  useEffect(() => {
    if (certificateResponsiblesSelected && certificateResponsiblesDataAll && filterDataSelected) {
      const certificatesFiltered = filterCertificates(
        certificateResponsiblesDataAll.filter(
          (certificate: ICertificateResponsibleListData) => certificateResponsiblesSelected.includes(certificate.guid_certificate)
        ), filterDataSelected
      );
      const totalPages: number = Math.round(certificatesFiltered.length/10);
      
      let meta: IDataMeta = {
        currentPage: 1,
        isFirstPage: true,
        isLastPage: totalPages === 1,
        nextPage: totalPages > 1 ? 2 : null,
        pageCount: totalPages,
        previousPage: null,
        totalCount: certificatesFiltered.length,
      };

      let start = 0;
      let end = start + 10;

      const orderedCertificates = sortCertificates(certificatesFiltered, certificateResponsiblesSelectedMeta);

      if (certificateResponsiblesSelectedMeta) {
        const { currentPage, totalPages, rowByPage } = certificateResponsiblesSelectedMeta;
        
        start = (currentPage - 1) * rowByPage;
        end = start + rowByPage;
  
        meta = {
          currentPage,
          isFirstPage: currentPage === 1,
          isLastPage: currentPage === totalPages,
          nextPage: currentPage === totalPages ? null : currentPage + 1,
          pageCount: Math.ceil(orderedCertificates.length / rowByPage),
          previousPage: currentPage === 1 ? null : currentPage - 1,
          totalCount: orderedCertificates.length
        }
      }

      if(certificateResponsiblesSelected.length === 0) {
        setShowOnlySelecteds(false);
      }

      const paginatedData = orderedCertificates.slice(start, end);
      
      setCertificateResponsiblesSelectedList({
        data: paginatedData,
        meta
      });

    }
  }, [certificateResponsiblesSelected, certificateResponsiblesSelectedMeta, certificateResponsiblesDataAll, refreshData]);

  const sortCertificates = (certificates: ICertificateResponsibleListData[], certificateMetaData?: ITableMetaData) => {
    const certificatesSort = certificates;
  
    const orderField = certificateMetaData?.orderField as keyof ICertificateResponsibleListData;
    const orderDirection = certificateMetaData?.orderDirection;
  
    const compareFunction = (a: any, b: any) => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    certificatesSort.sort(compareFunction);
  
    return certificatesSort;
  };
  
  const filterCertificates = (certificates: any, filterDataSelected: FilterDataSelected) => {
    let certificatesFiltered = certificates;

    if (certificatesFiltered.length > 0) {
      if (filterDataSelected[FilterTypeEnum.certificateCityFilter].length > 0) {
          certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
            return filterDataSelected[FilterTypeEnum.certificateCityFilter].includes(certificate.city?.id_city);
          });
      }

      if (filterDataSelected[FilterTypeEnum.certificateStateFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          return filterDataSelected[FilterTypeEnum.certificateStateFilter].includes(certificate.state?.id_state);
        });
      }

      if (filterDataSelected[FilterTypeEnum.jurisdictionFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          return filterDataSelected[FilterTypeEnum.jurisdictionFilter].includes(certificate.jurisdiction);
        });
      }

      if (filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          return filterDataSelected[FilterTypeEnum.companyCodeFilter].includes(certificate.entity.code);
        });
      }

      if (filterDataSelected[FilterTypeEnum.companyOrPersonaTypeFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          const entityType = certificate.entity.type === 0 || certificate.entity.type === 1 ? '4' : '3';
          return filterDataSelected[FilterTypeEnum.companyOrPersonaTypeFilter].includes(entityType);
        });
      }

      if (filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          return filterDataSelected[FilterTypeEnum.companyGroupFilter].includes(certificate.groups_names);
        });
      }

      if (filterDataSelected[FilterTypeEnum.holderFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          if (certificate.entity.type === 0 || certificate.entity.type === 1) {
            return filterDataSelected[FilterTypeEnum.holderFilter].includes(`company_${certificate.guid_company}`);
          } else {
            return filterDataSelected[FilterTypeEnum.holderFilter].includes(`persona_${certificate.guid_persona}`);
          }
        });
      }

      if (filterDataSelected[FilterTypeEnum.certificateTypeFilter].length > 0) {
        certificatesFiltered = certificatesFiltered.filter((certificate: any) => {
          return filterDataSelected[FilterTypeEnum.certificateTypeFilter].includes(certificate.slug.split('_')[0]);
        });
      }
    }

    return certificatesFiltered;
  }

  const getFilter = (): IQueryListResponsibleCertificate => {

    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const companies: string[] = [];
    const personas: string[] = [];

    if(filterDataSelected && filterDataSelected[FilterTypeEnum.holderFilter]) {
      filterDataSelected[FilterTypeEnum.holderFilter].forEach((entity) => {
        if (entity.includes('company_')) {
          companies.push(entity.replace('company_', ''));
        } else if (entity.includes('persona_')) {
          personas.push(entity.replace('persona_', ''));
        }
      });
    }

    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const entity_types: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyOrPersonaTypeFilter] : [];
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    const certificateTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateTypeFilter] : [];
    
    const jurisdictions: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const states: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStateFilter] : [];
    const cities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateCityFilter] : [];

    const responsibles: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.responsibleCertificateFilter] : [];
    const searchTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.searchTypeFilter] : [];

    const filters: IQueryListResponsibleCertificate = {
      page: metaData?.currentPage ?? 1,
      row_by_page: metaData?.rowByPage ?? -1,
      order_field: metaData?.orderField ?? 'createdAt',
      order_direction: metaData?.orderDirection ?? 'desc',
      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(personas) ? { personas: personas.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(entity_types) ? { entity_types: entity_types.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(certificateTypes) ? { slugs: certificateTypes.join(',') } : {}),
      
      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(states) ? { id_states: states.join(',') } : {}),
      ...(validFilterField(cities) ? { id_cities: cities.join(',') } : {}),
      ...(validFilterField(responsibles) ? { responsibles: responsibles.join(',') } : {}),
      ...(validFilterField(searchTypes) ? { is_manual: searchTypes.join(',') } : {}),
    };

    return filters;
  }

  const userName = () => {
    return props.user
      ? props.user.name
        ? props.user.name
        : props.user.email
      : 'selecionado';
  };

  const selectAll = () => {
    if(filterDataSelected) {
      if (certificateResponsiblesSelected.length === certificateGuidAll.length) {
        setCertificateResponsiblesSelected([]);
      } else {
        setCertificateResponsiblesSelected(certificateGuidAll);
      }
    }
  };

  const closeModal = () => {
    setShowOnlySelecteds(false);
    setCertificateResponsiblesData(null);
    onClose();
  }

  const getCertificatesToVinculate = async () => {
    setLoadingState(true);
    if (!props.user) return;

    if (!guidCollaborator) return;

    const filter = getFilter();

    const { status, response } = await getCertificateList(
      props.guid_client,
      guidCollaborator,
      filter
    );

    if (status === 200 && 'meta' in response) {
      if (
        response.meta.pageCount !== undefined &&
        response.data !== undefined
      ) {
        setCertificateResponsiblesData(response);
        if (selectedFromBackend) {
          setCertificateResponsiblesSelected(response.certificateResponsibles);
          setCertificateResponsiblesDataAll(response.allCertificates);
          setSelectecFromBackend(false);
        }
        setCertificateGuidAll(response.allGuidCertificates);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao tentar obter as informações das consultas',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description:
          'Ocorreu um erro ao tentar obter as informações das consultas',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setLoadingState(false);
  };

  const fetchCertificatesVinculated = async () => {
    if(!canEdit) return;

    setLoadingButton(true);
    if (!props.user) return;

    if (!guidCollaborator) return;

    const data = {
      guid_vinculates: certificateResponsiblesSelected,
    } as IVinculatedDataPost;

    const { status } = await postCertificatesVinculated(
      props.guid_client,
      guidCollaborator,
      data
    );

    if (status === 200) {
      toast({
        title: 'A consultas foram vinculadas com sucesso',
        description: '',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Ocorreu um erro ao tentar vincular as consultas',
        description: 'Favor contatar o suporte',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    closeModal();
    setLoadingButton(false);
  };

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.holderFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 2,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 3,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyOrPersonaTypeFilter,
          position: 4,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateTypeFilter,
          position: 5,
          size: 2,
        },
      ],
      position: 1,
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.jurisdictionFilter,
          position: 1,
          size: 2,
        },
        {
          name: FilterTypeEnum.responsibleCertificateFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.searchTypeFilter,
          position: 3,
          size: 3,
        },
        {
          name: FilterTypeEnum.certificateStateFilter,
          position: 4,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateCityFilter,
          position: 5,
          size: 2,
        },
      ],
      position: 2,
    },
  ] as FilterRow[];

  const columns = [
    {
      title: 'Consulta',
      name: 'certificate_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Empresa - (Cód.)',
      name: 'entity.displayName',
      orderFieldName: 'company.name,persona.name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Tipo',
      name: 'entity.type',
      orderFieldName: 'company.type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 0,
          textColor: '#365314',
          color: '#ECFCCB',
        },
        {
          text: 'Filial',
          value: 1,
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Pessoa Física',
          value: 3,
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
      ],
    },
    {
      title: 'CNPJ',
      name: 'entity.documentFormatted',
      orderFieldName: 'company.cnpj,persona.document',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Grupo',
      name: 'groups_names',
      orderFieldName: 'group_names',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Origem',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Estado',
      name: 'state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state.initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city.name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Responsáveis',
      name: 'responsibles',
      sortable: false,
      align: 'center',
      formatter: (value: string[]) => {
        if(value.length === 0) {
          return '-'
        }

        if(value.length === 1) {
          return value[0];
        }

        return `${value[0]} + ${value.length-1}`
      },
      tooltip: (row: any) => {
        if(row.responsibles) {
          return <Box>
            {row.responsibles.map((responsible: string) => (
              <Text>{responsible}</Text>
            ))}
          </Box>
        }
      }
    },
    {
      title: 'Tipo de Pesquisa',
      name: 'isManual',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Manual',
          value: true,
          textColor: '#3c12ad',
          color: '#9b77ff',
        },
        {
          text: 'Automática',
          value: false,
          textColor: '#543802',
          color: '#EFAD2C',
        },
      ],
    },
  ] as IColumn[];

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeModal}
        size='7xl'
      >
        <RetrieveCertificatesStyle>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
            alignItems='center'
          />
          <ModalContent width={1487} className='modal-content'>
            <ModalHeader width='100%' padding='8px 24px !important'>
              <Text className='title'>
                Vincular consultas para o usuário {userName()}
              </Text>
              <Text className='description'>
                <div className='description-text'>
                  <Flex className='blue-text'>
                    Selecione abaixo os critérios para vinculação das consultas.
                    A lista de consultas exibida abaixo será atualizada de
                    acordo com os filtros aplicados.
                  </Flex>
                </div>
              </Text>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody fontFamily='Poppins-Medium'>
              <Filters
                filters={filterRows}
                selectedValuesHook={setFilterDataSelected}
              />
              <>
                <Flex flexDirection='column' mt='15px' position='relative'>

                  <Flex
                    cursor="pointer"
                    position="absolute"
                    mt={8}
                    onClick={() => setShowOnlySelecteds(!showOnlySelecteds)}
                    display={
                      certificateResponsiblesSelected.length > 0
                      ? 'flex' : 'none'}
                  >
                    {showOnlySelecteds ? (
                      <Image w="40px" src="/icons/switch-blue.svg" />
                    ) : (
                      <Image w="40px" src="/icons/switch-gray.svg" />
                    )}

                    <Flex direction="column" ml={2}>
                      <Text fontSize="14px">
                          Exibir apenas registros selecionados
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} mt={8} mb={4}>                    
                    {
                      showOnlySelecteds ? 
                      (
                        <Datatables<any>
                          name="Consultas"
                          columns={columns}
                          metaDataHook={setCertificateResponsiblesSelectedMeta}
                          data={certificateResponsiblesSelectedList}
                          isLoading={isLoading}
                          showTotalRows={true}
                          heigth="400px"
                          checkBox={{
                            selecteds: certificateResponsiblesSelected,
                            setSelecteds: setCertificateResponsiblesSelected,
                            checkAll: selectAll,
                            checkAllDisabled: true,
                            key: 'guid_certificate',
                            disabled: !canEdit,
                          }}
                        />
                      ) : 
                      (
                        <Datatables<any>
                          name='Consultas'
                          columns={columns}
                          metaDataHook={setMetaData}
                          data={certificateResponsiblesData}
                          isLoading={isLoading}
                          showTotalRows={true}
                          heigth='400px'
                          checkBox={{
                            selecteds: certificateResponsiblesSelected,
                            setSelecteds: setCertificateResponsiblesSelected,
                            checkAll: selectAll,
                            key: 'guid_certificate',
                            disabled: !canEdit,
                          }}
                        />
                      )
                    }
                  </Flex>
                </Flex>
              </>
            </ModalBody>
            <ModalFooter>
              <Flex
                width='100%'
                justifyContent='end'
                alignItems='flex-end'
                display='flex'
              >
                <FormButton
                  isLoading={isLoadingButton}
                  disabled={!canEdit}
                  onClick={fetchCertificatesVinculated}
                  width='135px'
                >
                  Salvar
                </FormButton>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </RetrieveCertificatesStyle>
      </Modal>
    </>
  );
};
