import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  ButtonGroup,
  Text,
  useToast,
  Input,
  Box,
  Link,
} from "@chakra-ui/react";
import { DashInput, DashInputMask } from "../../Users/components/DashInput";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { AddIcon } from "@chakra-ui/icons";
import { postAddCND } from "../../../../_services/cnd.service";
import moment from "moment";
import { EToastStatus } from "../../../../components/ToastEnum";
import { adjustJurisdiction } from "../../TaxKanban/TaxKanbanUtils";
import { EJurisdiction } from "../../TaxKanban/cardRegistration.dto";
import { getCityByState, getStates } from "../../../../_services/region.service";
import { ICityResponse, ICountry, IRegionResponse } from "../../../../_services/interface/region.interface";
import { IValueLabel } from "../../Companies/Company/modals/EditCompanyModal";
import { canInsertEntity, getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import { ICertificate, IStatus } from "../../../../_services/interface/certificate.interface";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { getClientCompanies } from "../../../../_services/company.service";
import { SelectPersonas } from "../../../../modal/SelectPersonas";
import { listWithoutPermissionPersonas } from "../../../../_services/persona.service";
import { IPersona } from "../../../../_services/interface/persona.interface";
import CustomAsyncSelect, { IOption } from "../../../../components/CustomAsyncSelect";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { ICNDListData } from "../../../../_services/interface/cnd.interface";

// import { readFile } from "./cndUtils";
interface IAddCNDForm {
  //company: string;
  jurisdiction: string;
  cnd_code: string;
  state: number | null;
  city: number | null;
  situation: string | null;
  issuedAt: string;
  validUntil: string;
  issued: string;
  file?: FileList;
  cnpj: string;
  entityType: string;
  type: string;
}

interface IAddCNDnModalProps {
  currentClient: IClientDataResponse | null;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
  type: string;
  certificate: ICertificate;
  entityType: string;
  initialCert: ICNDListData | null;
  initialCertHook: React.Dispatch<React.SetStateAction<ICNDListData | null>>;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddNewCNDModal = (props: IAddCNDnModalProps) => {
  const getEntityTypeLabel = () => {
    const entityTypeValue = props.entityType;

    const entityTypeLabels: { [key: string]: string } = {
      'all': 'empresa ou pessoa física',
      'company': 'empresa',
      'persona': 'pessoa física'
    };

    return entityTypeLabels[entityTypeValue] || entityTypeLabels['all'];
  }

  const YupCompanyChangeForm = Yup.object().shape({
    // company: Yup.string().required("Empresa"),
    jurisdiction: Yup.string().required("Jurisdição"),
    state: Yup.number(),
    city: Yup.number(),
    entityType: Yup.string(),
    type: Yup.string().required("Tipo de Certidão"),
    //cnd_code: Yup.string().required("Número da Certidão"),
    //issuedAt: Yup.string().required("Data de emissão"),
    //validUntil: Yup.string().required("Data de vencimento"),
    issued: Yup.string().notOneOf(['Situação', 'Situação da certidão']).required("Situação da certidão"),
    file: Yup.mixed()
       .nullable()
       .notRequired()
       .test("FILE_FORMAT", "Insira um arquivo PDF.", value => !value || (value && SUPPORTED_FORMATS.includes(value[0].type)))
  });

  const certificateStatuses = [] as IStatus[];
  props.certificate?.status?.filter((stat) => !['error', 'not-renewed', 'no-registry'].includes(stat.slug))?.forEach((stat) => {
    if (stat.name.includes('*')) {
      certificateStatuses.push({
        ...stat,
        name: stat.name + ' (Positiva c/ efeito Negativa)'
      });
      return;
    }
    certificateStatuses.push({
      ...stat
    });
  });

  const SUPPORTED_FORMATS = ["application/pdf"]
  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const [selectedState, setSelectedState] = useState<number>();
  // eslint-disable-next-line 
  const [selectedCity, setSelectedCity] = useState<number>();

  const [states, setStates] = useState<IValueLabel[]>([]);
  const [cities, setCities] = useState<IValueLabel[]>([]);
  const [citiesRaw, setCitiesRaw] = useState<ICountry[]>([]);

  const [type, setType] = useState<string>();
  const [certificateStatus, setCertificateStatus] = useState<IStatus|null>(certificateStatuses.filter((status) => !status.isIssued)[0]);
  const [jurisdiction, setJurisdiction] = useState<EJurisdiction[]>([]);
  const [showCertificateType, setShowCertificateType] = useState<boolean>(false);

  const fileUpload = useRef<HTMLInputElement>(null);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [fileUploadValue, setFileUploadValue] = useState<FileList>();
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const { formState: { errors }, reset, register, getValues, setValue, watch, handleSubmit } = useForm<IAddCNDForm>(resolverForm);
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [personas, setPersonas] = useState<IPersona[]>([]);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);
  const [openSelectPersonas, setOpenSelectPersonas] = useState<boolean>(false);
  const [isLoadingCompany, setLoadingCompany] = useState<boolean>(true);
  const [isLoadingPersonas, setLoadingPersonas] = useState<boolean>(true);
  const [entityType, setEntityType] = useState<string>();

  const [certificatesOptions, setCertificateOptions] = useState<IOption[]>([]);

  const toast = useToast();
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedPersonas, setSelectedPersonas] = useState<string[]>([]);

  const validateFile = () => {
    if (fileUploadValue) {
      if (fileUploadValue.item(0)) {
        if (SUPPORTED_FORMATS.includes(fileUploadValue.item(0)?.type as string)) {
          return true;
        } else {
          setSimpleErrorMessage('Apenas arquivos PDFs são aceitos');
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const handleFormInfo = async (data: IAddCNDForm) => {
    setSubmitDisabled(true);
    setSimpleErrorMessage('');

      let issuedAt: string | undefined = undefined;
      let validUntil: string | undefined = undefined;
      let willRegister = true;

      if (certificateStatus?.isIssued) {
        if (!data.issuedAt && !data.validUntil) {
          setSimpleErrorMessage('Você precisa informar tanto a Data de Emissão quanto a Data de Validade');
          willRegister = false;
        } else {
          if (moment.utc(data.issuedAt, 'DD/MM/YYYY').isValid() && moment.utc(data.validUntil, 'DD/MM/YYYY').isValid()) {
            if (moment.utc(data.validUntil, 'DD/MM/YYYY').diff(moment.utc(data.issuedAt, 'DD/MM/YYYY'), 'days') === 0) {
              setSimpleErrorMessage('O vencimento não pode ser no mesmo dia da emissão');
              willRegister = false;
            } else if (moment.utc(data.validUntil, 'DD/MM/YYYY').diff(moment.utc(data.issuedAt, 'DD/MM/YYYY'), 'days') < 0) {
              setSimpleErrorMessage('O vencimento não pode ser em uma data antes do dia da emissão');
              willRegister = false;
            } else {
              //Here the system validate the dates
              let datesAreValid = true;
              if (moment.utc(data.validUntil, 'DD/MM/YYYY').isValid() && moment.utc(data.issuedAt, 'DD/MM/YYYY').isValid()) {
                if (parseInt(moment.utc(data.validUntil, 'DD/MM/YYYY').format('YYYY')) >= parseInt(moment.utc().add(20, 'years').format('YYYY'))) {
                  datesAreValid = false;
                  willRegister = false;
                  setSimpleErrorMessage('Por favor, informe um ano de validade válida que não seja maior que 20 anos da data corrente.');
                }
    
                if (parseInt(moment.utc(data.validUntil, 'DD/MM/YYYY').format('YYYY')) <= parseInt(moment.utc().subtract(20, 'years').format('YYYY'))) {
                  datesAreValid = false;
                  willRegister = false;
                  setSimpleErrorMessage('Por favor, informe um ano de validade válida que não seja menor que 20 anos da data corrente.');
                }
    
                if (parseInt(moment.utc(data.issuedAt, 'DD/MM/YYYY').format('YYYY')) >= parseInt(moment.utc().add(20, 'years').format('YYYY'))) {
                  datesAreValid = false;
                  willRegister = false;
                  setSimpleErrorMessage('Por favor, informe um ano de emissão válido que não seja maior que 20 anos da data corrente.');
                }
    
                if (parseInt(moment.utc(data.issuedAt, 'DD/MM/YYYY').format('YYYY')) <= parseInt(moment.utc().subtract(20, 'years').format('YYYY'))) {
                  datesAreValid = false;
                  willRegister = false;
                  setSimpleErrorMessage('Por favor, informe um ano de emissão válido que não seja menor que 20 anos da data corrente.');
                }
              } else {
                datesAreValid = false;
                willRegister = false;
                setSimpleErrorMessage('Você precisa informar tanto a Data de Emissão quanto a Data de Validade');
              }
    
              if (datesAreValid) {
                issuedAt = moment.utc(data.issuedAt, 'DD/MM/YYYY').format();
                validUntil = moment.utc(data.validUntil, 'DD/MM/YYYY').format();
              }
            }
          } else {
            setSimpleErrorMessage('Por favor, insira uma data de emissão e validade válida');
            willRegister = false;
          }
        }
      }

      if (selectedCompanies.length === 0 && selectedPersonas.length === 0) {
        if (selectedCompanies.length === 0) {
          setSimpleErrorMessage('Você precisa selecionar uma empresa');
        }

        if (selectedPersonas.length === 0) {
          setSimpleErrorMessage('Você precisa selecionar uma pessoa');
        }
        willRegister = false;
      }

      if(data.jurisdiction === EJurisdiction.state || data.jurisdiction === EJurisdiction.municipal) {
        if (!data.state) {
          setSimpleErrorMessage('Você precisa selecionar um estado');
          willRegister = false;
        }
      }

      if(data.jurisdiction === EJurisdiction.municipal) {
        if (!data.city) {
          setSimpleErrorMessage('Você precisa selecionar uma cidade');
          willRegister = false;
        }
      }

      if (willRegister && (selectedCompanies || selectedPersonas)) {
        if (validateFile()) {
          const stateInitials = states.find((item) => item.value === data.state)?.initials;
          const cityLabel = cities.find((item) => item.value === data.city)?.label;
          const { status } = await postAddCND(props.guid_client, {
            ...(data.entityType === 'company' ? {guid_companies: selectedCompanies} : {guid_companies: []}),
            ...(data.entityType === 'persona' ? {guid_personas: selectedPersonas} : {guid_personas: []}),
            type: data.type,
            state: data.jurisdiction !== EJurisdiction.federal && data.state && stateInitials ? stateInitials : null,
            city: data.jurisdiction === EJurisdiction.municipal && data.city && cityLabel ? cityLabel : null,
            cnd_code: data.cnd_code,
            situation: null,
            issuedAt: issuedAt,
            validUntil: validUntil,
            slug_status: certificateStatus?.slug ?? '',
            jurisdiction: data.jurisdiction,
            file: fileUploadValue?.item(0),
          });            

          if (status === 200) {
            toast({
              title: "Certidão adicionada com sucesso.",
              description: "Sua certidão foi adicionada.",
              status: EToastStatus.SUCCESS,
              duration: 5000,
              isClosable: true,
            });

            props.flushHook(true);
            closeModal();
          } else {
            toast({
              title: "Falha ao Adicionar a certidão.",
              description: "Já existe uma certidão registrada com esse número.",
              status: EToastStatus.ERROR,
              duration: 5000,
              isClosable: true,
            });
          }
        }
      }

    setSubmitDisabled(false);
  }

  const handleTypeChange = (value: string) => {
    configCertificateOptions();
    if (value !== "municipal") {
      setSelectedCity(0);
    }
    if (value !== "state" && value !== "municipal") {
      setSelectedState(0);
    }

    setType(value);
  };

  const getCertificateStatusBySlug = (slug: string|null|undefined) => {
    const statusFilter = certificateStatuses.filter((status) => status.slug === slug);

    return statusFilter.length ? statusFilter[0] : null;
  };

  const handleStatusChange = (value: string) => {
    const newCertificateStatus = getCertificateStatusBySlug(value);

    setCertificateStatus(newCertificateStatus);
  };

  const cityList = async (id_state: number) => {
    const { status, response } = await getCityByState(id_state);
    if (status === 200) {
      const cities: IValueLabel[] = [];
      (response as ICityResponse).cities.forEach((_x) => {
        cities.push({
          value: _x.id_city,
          label: _x.name,
          initials: ""
        });
      });

      if (cities) {
        setCities(cities);
        setCitiesRaw((response as ICityResponse).cities);
        setSelectedCity(cities[0].value); 
        configInitialCertificate();
      }
    } else {
      toast({
        title: 'Falha ao listar os estados',
        description: `Ocorreu um erro ao tentar listar as cidades, por favor, tente novamente.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const stateList = async () => {
    const { status, response } = await getStates();

    if (status === 200) {
      const states: IValueLabel[] = [];
      (response as IRegionResponse[]).forEach((_e) => {
        states.push({
          value: _e.id_state,
          label: _e.name,
          initials: _e.initials
        })
      });

      if (states) {
        setStates(states);
        setSelectedState(states[0].value); 
      }
    } else {
      toast({
        title: 'Falha ao listar os estados',
        description: `Ocorreu um erro ao tentar listar os estados, por favor, tente novamente.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const getJusrisdiction = () => {
    const jurisdictionValues = getCertificatesPropertyValuesRecursively([props.certificate], 'jurisdiction')[0];
    
    let allowedOptions = jurisdictionValues.filter((value: string) => {
      return Object.keys(EJurisdiction).includes(value);
    }) as EJurisdiction[];

    allowedOptions = Array.from(new Set(allowedOptions));

    return allowedOptions;
  }

  useEffect(() => {
    if(isOpen) {
      loadCompanies();
      loadPersonas();
      stateList();
      const jurisdictionsToUse = getJusrisdiction();
      setJurisdiction(jurisdictionsToUse);

      if(props.entityType === 'all' || !canInsertEntity(props.certificate.config_persona)) {
        setValue('entityType', 'company');
      } else {
        setValue('entityType', props.entityType);
      }

      setValue('jurisdiction', jurisdictionsToUse[0] as EJurisdiction);
      handleTypeChange(jurisdictionsToUse[0]);
      configInitialCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    configCertificateOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.certificate, selectedState, selectedCity])

  useEffect(() => {
    watch((value) => {
      // Check if the state is different
      if (value.state !== null && value.state !== selectedState) {
        setSelectedState(value.state);
      }

      if (value.city !== null && value.city !== selectedCity) {
        setSelectedCity(value.city);
      }

      if(value.entityType && value.entityType !== entityType) {
        setEntityType(value.entityType);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);  

  useEffect(() => {
    if (selectedState) {
      cityList(selectedState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (errors) {
      setAccErrorMessage(
        Object.values(errors).map((_x) => _x.message) as string[]
      );
    }
  }, [errors]);

  useEffect(() => {
    if (props.openModal) {
      onOpen();
      configCertificateOptions();
      configInitialCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  const configCertificateOptions = () => {
    setCertificateOptions([]);
    setValue('type', '');
    const state = states.find((s) => s.value === getValues('state'))?.initials;
    const city = citiesRaw.find((c) => c.id_city === getValues('city'))?.normalized_name;

    if(props.certificate.hasSimilarCertificate) {
      const options: IOption[] = [];
      props.certificate.children?.forEach((child) => {
        if (child.hasSimilarCertificate &&
          child.jurisdiction === getValues('jurisdiction') &&
          (
            getValues('jurisdiction') === 'federal' ||
            (getValues('jurisdiction') === 'state' && (child.allow_states === 'all' || (state && child.allow_states?.includes(state)))) ||
            (getValues('jurisdiction') === 'municipal' && (child.allow_cities === 'all' || (city && child.allow_cities?.includes(city))))
          )
        ) {
          options.push({
            value: child.slug as string,
            label: child.name as string
          });
        }
      });

      if (options.length > 0) {
        setCertificateOptions(options);
        setValue('type', options[0].value as string);
        setShowCertificateType(true);
      } else {
        setValue('type', props.certificate.slug);
        setShowCertificateType(false);
      }
    } else {
      setValue('type', props.certificate.slug);
      setShowCertificateType(false);
    }
  };

  const validUntilStatus = () => {
    const newCertificateStatus = getCertificateStatusBySlug(certificateStatus?.slug);

    return newCertificateStatus?.isIssued;
  }

  const loadCompanies = async () => {
    setLoadingCompany(true);
    const { status, response } = await getClientCompanies(props.guid_client);
    if (status === 200) {
      setAllCompanies(response as IClientCompanies[]);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar obter as informações dos usuários',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingCompany(false);
  }

  const loadPersonas = async () => {
    setLoadingPersonas(true);

    const filters = {
      page: 1,
      row_by_page: '-1',
    };
    const { status, response } = await listWithoutPermissionPersonas(props.guid_client, filters);

    if (
      status === 200
      && 'meta' in response 
      && 'data' in response
      && response.meta.pageCount !== undefined
      && response.data !== undefined
    ) {
        const data: IPersona[] = response.data;

        setPersonas(data);
    }

    setLoadingPersonas(false);
  }

  const validatePersonaManualOption = () => {
    if(
      canInsertEntity(props.certificate.config_persona) 
      && props.currentClient 
      && props.currentClient.products_cpf 
      && (
        props.currentClient.products_cpf[props.certificate.slug as string]
        || props.certificate.children?.some((child) => props.currentClient?.products_cpf[child.slug as string] === true)
      )
    ) {
      return true;
    }
    return false;
  };

  const configInitialCertificate = () => {
    if (props.initialCert) {
      setValue('type', props.initialCert.slug);
      setValue('entityType', props.initialCert.guid_persona ? 'persona' : 'company');
      setSelectedCompanies(props.initialCert.guid_company ? [props.initialCert.guid_company] : []);
      setSelectedPersonas(props.initialCert.guid_persona ? [props.initialCert.guid_persona] : []);
      setValue('jurisdiction', props.initialCert.jurisdiction as EJurisdiction);
      handleTypeChange(props.initialCert.jurisdiction as string);
      setSelectedState(props.initialCert.state ? props.initialCert.state.id_state : undefined);
      setValue('state', props.initialCert.state ? props.initialCert.state.id_state : 1);
      setSelectedCity(props.initialCert.city ? props.initialCert.city.id_city : undefined);
      setValue('city', props.initialCert.city ? props.initialCert.city.id_city : 1);
    }
  };

  const closeModal = () => {
    props.initialCertHook(null);
    props.openModalHook(false);
    reset();
    setSelectedCompanies([]);
    setSelectedPersonas([]);
    onClose();
  }

  return (
    <Flex >
      <ButtonGroup onClick={() => props.openModalHook(true)} size="sm" mt="24px" isAttached color="white" >
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
          Cadastro manual
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="xl" >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF" >
          <ModalHeader fontFamily="Poppins-Medium">Adicionar Certidão</ModalHeader>
          <ModalCloseButton /> 
          <ModalBody fontFamily="Poppins-Medium">
            {accErrorMessage.length > 0 ? (
              <Flex my="8px" bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}
            <Flex direction="column" gap={2}>
              <Flex direction="column" fontSize="12px" opacity={0.5} mb={4}>
                Cadastre uma nova certidão para uma {getEntityTypeLabel()}.
              </Flex>
              
              <Flex height="40px" gap={5}>
                <Flex w="50%" direction="column">
                  {
                    entityType === 'company' ? 
                    (<>
                      {isLoadingCompany ? (
                        <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                          Carregando Empresas
                        </Flex>
                      ) :
                        <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                          <Link color="#4B4EFF" fontWeight="bold" onClick={() => setOpenSelectCompanies(true)}>{selectedCompanies.length > 0 ? selectedCompanies.length > 1 ? `${selectedCompanies.length} empresas selecionadas` : `${selectedCompanies.length} empresa selecionada` : 'Selecione a Empresa'}</Link>
                        </Flex>
                      }
                    </>)
                    :
                    (<>
                      {isLoadingPersonas ? (
                        <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                          Carregando Pessoas
                        </Flex>
                      ) :
                        <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                          <Link color="#4B4EFF" fontWeight="bold" onClick={() => setOpenSelectPersonas(true)}>{selectedPersonas.length > 0 ? selectedPersonas.length > 1 ? `${selectedPersonas.length} pessoas físicas selecionadas` : `${selectedPersonas.length} pessoa física selecionada` : 'Selecione a Pessoa Física'}</Link>
                        </Flex>
                      }
                    </>)
                  }
                </Flex>

                <Flex w="50%" direction="column">
                  <CustomAsyncSelect
                    placeholder=""
                    options={[
                      {
                        value: 'company',
                        label: 'Empresa'
                      },
                      ...(
                        validatePersonaManualOption() ? 
                        [{
                          value: 'persona',
                          label: 'Pessoa Física'
                        }] : []
                      )
                    ]}
                    setValue={(value: string | number) => {
                      setValue('entityType', String(value));
                    }}
                    value={getValues('entityType')}
                  />
                </Flex>
              </Flex>

              <SelectCompanies
                guid_client={props.guid_client}
                companies={allCompanies}
                open={openSelectCompanies}
                setOpen={setOpenSelectCompanies}
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={setSelectedCompanies}
                header="Selecionar Empresas"
                description=""
                buttonText="Salvar alteração"
                isLoading={isLoadingCompany}
              />

              {canInsertEntity(props.certificate.config_persona) ?
                (<SelectPersonas
                  personas={personas}
                  open={openSelectPersonas}
                  setOpen={setOpenSelectPersonas}
                  selectedPersonas={selectedPersonas}
                  setSelectedPersonas={setSelectedPersonas}
                  header="Selecionar Pessoas Físicas"
                  description=""
                  buttonText="Salvar alteração"
                  guid_client={props.guid_client}
                  isLoading={isLoadingPersonas}
                />)
                : null
              }

              <DashInput 
                type="text"
                borderBottomColor="gray.300"
                p="6px"
                mt="10px"
                borderRadius={6}
                textTransform="uppercase"
                placeholder="Código da Certidão (Opcional)"
                {...register("cnd_code")}
              />
              
              <Flex gap={4} mt="10px">
                <Flex w="50%" flexDirection="column">
                  <CustomAsyncSelect
                    placeholder="Jurisdição"
                    options={Object.values(jurisdiction).map((info) => ({
                      value: info,
                      label: adjustJurisdiction(info) as string,
                    }))}
                    setValue={(value: string | number) => {
                      setValue('jurisdiction', String(value));
                      handleTypeChange(String(value));
                    }}
                    value={getValues('jurisdiction')}
                  />
                </Flex>
                
                <Flex w="50%" flexDirection="column">
                  <CustomAsyncSelect
                    placeholder="Situação"
                    options={certificateStatuses.map((info) => ({
                      value: info.slug,
                      label: info.name,
                    }))}
                    setValue={(value: string|number) => {
                      setValue('issued', String(value));
                      handleStatusChange(String(value));
                    }}
                    value={getValues('issued')}
                  />
                </Flex>
              </Flex>

              {type === 'state' || type === 'municipal' ? (
                <Flex gap={4} mt="10px">
                  <Flex w="50%" direction="column">
                    <CustomAsyncSelect
                      placeholder="Selecione o estado"
                      options={states}
                      setValue={(value: string | number) => {
                        setValue('state', Number(value));
                      }}
                      value={getValues('state')}
                    />
                  </Flex>
                  {type === 'municipal' ? (
                    <Flex w="50%" direction="column">
                      <CustomAsyncSelect
                        placeholder="Selecione a cidade"
                        options={cities}
                        setValue={(value: string | number) => {
                          setValue('city', Number(value));
                        }}
                        value={getValues('city')}
                        isDisabled={!getValues('state')}
                      />
                    </Flex>
                  ) : null}
                </Flex>
              ) : null}

              {
                showCertificateType ? (
                  <Flex gap={4} mt="10px">
                    <Flex w="100%" flexDirection="column">
                      <CustomAsyncSelect
                        placeholder="Tipo de Certidão"
                        options={certificatesOptions}
                        setValue={(value: string | number) => {
                          setValue('type', String(value));
                        }}
                        value={getValues('type')}
                      />
                    </Flex>
                  </Flex>
                ) : null
              }

              {validUntilStatus() ? (
                <Box borderRadius={5} mt="10px">
                  <Flex gap={4}>
                    <DashInputMask mask="99/99/9999" type="text" placeholder="Data de Emissão" fontSize="12px" {...register("issuedAt")} />
                    <DashInputMask mask="99/99/9999" type="text" placeholder="Data de Validade" fontSize="12px" {...register("validUntil")} />
                  </Flex>
                </Box>
              ) : null}
              
              <Flex alignItems="stretch" gap={2} direction="column" mt={4}>
                {fileUploadValue ? (
                  <Flex>
                    <Text fontSize="12px">{fileUploadValue.item(0)?.name}</Text>
                  </Flex>
                ) : null}
                <FormButton onClick={() => fileUpload.current?.click() }>Carregar arquivo</FormButton>
                <Input ref={fileUpload} type="file" position="absolute" left="-9999" onChange={event => setFileUploadValue(event.target.files as FileList)} />
                <Flex className="situations" direction="column" fontSize="12px" opacity={0.5}>
                  O envio de documentos para registro desta certidão é opcional.
                </Flex>
              </Flex>
            </Flex>
            <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} mt={8} mb={4} >
              <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
                Adicionar nova certidão
              </FormButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
