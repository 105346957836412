import { ChevronDownIcon } from "@chakra-ui/icons";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Link, Flex, Textarea, InputGroup, Menu, MenuButton, Box, MenuList, MenuItem, Image, useToast, Text } from '@chakra-ui/react'
import { useEffect, useState } from "react";
import { TaxlyInput } from '../../../../../../components/Input';
import { SelectCollaboratorModal } from "../../../../Users/modais/SelectCollaboratorModal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { createCard } from "../../../../../../_services/irs.service";
import { EPriority, EFrequencyType } from "../../../../../../_services/enum/irs.enum";
import moment from "moment";
import DatePickerWithOptions from "../DatePickerWithOptions";
import { IAddNewTaskForm, ICNDListDataSchema } from '../Interface/IAddNewTaskForm'
import { IAddNewTaskModal } from '../Interface/IAddNewTaskModal';
import { CompanyFilterAndTable } from "./CompanyFilterAndTable";
import { ICNDListData } from "../../../../../../_services/interface/cnd.interface";
import { ICreateCardCompany } from "../../../../../../_services/interface/irs.interface";
import { CertificateStaticFilterAndTable } from "./CertificateStaticFilterAndTable";
import SelectWithIcon from "../../../../../../components/SelectWithIcon";

export const AddNewTask = (props: IAddNewTaskModal) => {
  const toast = useToast();
  const { isOpen, onClose } = props;
  const [dueDate, setDueDate] = useState<Date|undefined>();
  const [frequencyType, setFrequencyType] = useState<EFrequencyType|null>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<number|null>(null);

  const [disabledButtonSave, setDisabledButtonSave] = useState<boolean>(false);

  // Collaborator
  const [openSelectCollaborator, setOpenSelectCollaborator] = useState<boolean>(false);
  const [selectedsCollaborator, setSelectedsCollaborator] = useState<string[]>([]);

  //Company Data
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);

  // Certificates
  const [certificateChecked, setCertificateChecked] = useState<ICNDListData[]>([]);

  const priorityOptions = [
    { value: EPriority.URGENT, label: 'Urgente', icon: <Image w="22px" h="22px" src="/icons/flags/red.svg" /> },
    { value: EPriority.HIGH, label: 'Alta', icon: <Image w="22px" h="22px" src="/icons/flags/orange.svg" /> },
    { value: EPriority.REGULAR, label: 'Normal', icon: <Image w="22px" h="22px" src="/icons/flags/blue.svg" /> },
    { value: EPriority.LOW, label: 'Baixa', icon: <Image w="22px" h="22px" src="/icons/flags/gray.svg" /> },
  ];

  const [selectedOption, setSelectedOption] = useState(priorityOptions[0]);

  const YupCompanyChangeForm = Yup.object().shape({
    companies: props.type === 'company' ? Yup.array().of(Yup.string()).min(1, "Selecione ao menos 1 empresa").required("Empresas são obrigatórias") : Yup.array().of(Yup.string()).nullable(),
    certificates: props.type === 'certificate' ? Yup.array().min(1, "Selecione ao menos 1 certidão").required("Certidões são obrigatórias") : Yup.array().of(Yup.string()).nullable(),
    collaborators: Yup.array().of(Yup.string()).min(1, "Selecione ao menos 1 responsável").required("Colaboradores são obrigatórios"),
    priority: Yup.string().required("Prioridade é obrigatória"),
    name: Yup.string().required("Nome é obrigatório"),
    description: Yup.string().nullable(),
    dueDate: Yup.string()
      .required("Data de vencimento é obrigatória")
      .test('isValidDate', 'Data de vencimento inválida', (value) => {
        return moment(value, 'DD/MM/YYYY', true).isValid();
      }),
  });

  const resolverForm = {resolver: yupResolver(YupCompanyChangeForm)};
  const { formState: { errors }, register, getValues, setValue, watch, handleSubmit, reset } = useForm<IAddNewTaskForm>(resolverForm);

  const handleFormInfo = async (data: IAddNewTaskForm) => {
    setDisabledButtonSave(true);
    const formattedDueDate = moment(data.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    
    let companies: ICreateCardCompany[] = data.companies.map((company) => ({
      'guid_company': company,
      'certificateSlug': null,
      'certificateType': null,
      'certificateState': null,
      'certificateCity': null,
      'guidCnd': null,
    }));

    if(companies.length === 0) {
      companies = data.certificates.map((certificate) => ({
        'guid_company': certificate.guid_company,
        'certificateSlug': certificate.isManual ? null : certificate.slug,
        'certificateType': certificate.slug.split('_')[0],
        'certificateState': certificate.state ? certificate.state.initials : null,
        'certificateCity': certificate.city ? certificate.city.name : null,
        'guidCnd': certificate.guid_cnd,
      }));
    }
    const frequencyTypeToSend = frequencyType ? (frequencyType as EFrequencyType) : undefined;
    const frequencyUnitToSend = frequencyUnit ? frequencyUnit : undefined;

    const { status } = await createCard(props.guid_client, {
      name: data.name,
      description: data.description,
      priority: data.priority as EPriority,
      hasRecurrency: frequencyTypeToSend && frequencyUnitToSend ? true : false,
      frequencyType: frequencyTypeToSend,
      frequencyUnit: frequencyUnitToSend,
      companies: companies,
      responsibles: data.collaborators,
      dueDate: formattedDueDate,
      cardStructureType: data.certificates.length > 0 ? `CERTIFICATE_COMPANY` : `COMPANY`
    });

    if(status === 200) {
      props.flushHook(true);
      props.onClose();

      toast({
        title: 'Tarefa criada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar criar a tarefa',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
    setDisabledButtonSave(false);
  }

  useEffect(() => {
    setValue('dueDate', dueDate?.toLocaleDateString('pt-BR') ?? '');
  }, [dueDate]);
  
  useEffect(() => {
    setValue('companies', companyChecked);
  }, [companyChecked]);

  useEffect(() => {
    setValue('certificates', certificateChecked);

    const collaborators: string[] = [];
    certificateChecked.map((certificate) => {
      if(certificate.reponsibles_guid_collaborator && certificate.reponsibles_guid_collaborator.length > 0) {
        certificate.reponsibles_guid_collaborator.map((guid) => collaborators.push(guid));
      }
    });

    const unifiedCollaborators = [...new Set([...collaborators, ...selectedsCollaborator])];
    setSelectedsCollaborator(unifiedCollaborators)
  }, [certificateChecked]);


  useEffect(() => {
    setValue('priority', selectedOption?.value);
  }, [selectedOption]);

  useEffect(() => {
    setValue('collaborators', selectedsCollaborator);
  }, [selectedsCollaborator]);

  useEffect(() => {
    if(isOpen) {
      reset({
        companies: [],
        certificates: [],
        collaborators: [],
        name: '',
        description: '',
        priority: EPriority.LOW,
        dueDate: ''
      })

      setDueDate(undefined);
      setFrequencyType(null);
      setFrequencyUnit(null);
      setSelectedsCollaborator([]);
      setCompanyChecked([]);
      setCertificateChecked([]);
      setSelectedOption(priorityOptions[0]);

      if(props.dataCertificate && props.dataCertificate.reponsibles_guid_collaborator) {
        setSelectedsCollaborator(props.dataCertificate.reponsibles_guid_collaborator);
      }
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
          alignItems="center"
        />

        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderLeft="12px solid var(--primary-500)"
          borderRadius={8}
          maxW={870}
        >
          <ModalHeader pl={4} fontFamily="Poppins-SemiBold" pb={3}>
            Nova tarefa
          </ModalHeader>

          <ModalBody pl={4}>
            <Flex flexDirection="column">
              <TaxlyInput
                fontFamily="Poppins-medium"
                label={'Nome da tarefa/card'}
                placeholder="Digite um título para o card"
                labelCustomStyle={{ mb: 1, fontWeight: 500 }}
                isRequired={true}
                _placeholder={{ color: '#CBD5E0' }}
                {...register("name")}
              />

              {
                errors.name && (
                  <Text color="red" fontSize={"12px"}>
                    {errors.name.message}
                  </Text>
                )
              }

              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                fontSize="12px"
                mt={5}
                fontWeight={500}
                fontFamily={'Poppins-Medium'}
              >
                <label htmlFor="description">Descrição</label>

                <Textarea
                  mt={1}
                  id="description"
                  placeholder="Escreva uma descrição para o card..."
                  isRequired={true}
                  fontSize='12px'
                  fontFamily='Poppins-medium'
                  borderRadius='4px'
                  border='1px solid #CBD5E0'
                  bgColor='transparent'
                  fontWeight={100}
                  bg="#FFFFFF"
                  _placeholder={{ color: '#CBD5E0' }}
                  {...register("description")}
                />
              </Flex>
            </Flex>

            <Flex flexDirection={'row'}>
              <Flex
                mt={5}
                gap={2}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Flex w="33%">
                  <Flex flexDirection="column" w="100%" zIndex={9999999999}>
                    <label
                      htmlFor="duedate"
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Poppins-Medium',
                        marginBottom: '5px',

                      }}
                    >
                      Data de vencimento
                    </label>

                    <InputGroup
                      w="100%"
                      border="1px solid var(--chakra-colors-chakra-border-color)"
                      borderRadius={6}
                    >
                      <Flex flexDirection="column" w="100%" cursor={'pointer'}>
                        <DatePickerWithOptions
                          id="duedate"
                          placeholder="Data de Vencimento"
                          setSelectedDay={setDueDate}
                          setFrequencyType={setFrequencyType}
                          frequencyType={frequencyType}
                          setFrequencyUnit={setFrequencyUnit}
                          frequencyUnit={frequencyUnit}
                        />
                      </Flex>
                    </InputGroup>

                    {
                      errors.dueDate && (
                        <Text color="red"
                              fontSize={'12px'}>{errors.dueDate.message}</Text>
                      )
                    }
                  </Flex>
                </Flex>

                <SelectWithIcon
                  id="priority"
                  label="Prioridade"
                  options={priorityOptions}
                  selected={selectedOption}
                  setSelectedOptions={setSelectedOption}
                  width="33%"
                />

                <Flex w="33%" flexDirection={'column'}>
                  <label
                    htmlFor="responsible"
                    onClick={() => setOpenSelectCollaborator(true)}
                    style={{
                      fontSize: '12px',
                      flexDirection: "column",
                      fontWeight: 500,
                      fontFamily: 'Poppins-Medium',
                      marginBottom: '5px',
                    }}
                  >
                    Responsável
                  </label>

                  <InputGroup>
                    <Flex flexDirection="column" w="100%">
                      <Flex
                        border="1px solid #CACACA"
                        fontSize="12px"
                        w={'100%'}
                        height="32px"
                        borderRadius={6}
                        p="4px 8px"
                        justifyContent="flex-start"
                        alignItems="center"
                        cursor={'pointer'}
                        onClick={() => setOpenSelectCollaborator(true)}
                      >
                        <Image src="/icons/persona.svg"/>

                        <Link
                          ml="5px"
                          fontSize="12px"
                          fontFamily="Poppins-medium"
                          color="#4A5568"
                        >
                          {
                            selectedsCollaborator.length > 0
                              ? selectedsCollaborator.length > 1
                                ? `${selectedsCollaborator.length} selecionados`
                                : `${selectedsCollaborator.length} selecionado`
                              : 'Responsável'
                          }
                        </Link>

                        <SelectCollaboratorModal
                          isMulti={true}
                          bigSelector={true}
                          guid_client={props.guid_client}
                          openModal={openSelectCollaborator}
                          openModalHook={setOpenSelectCollaborator}
                          callback={(collaborators: string[]) => setSelectedsCollaborator(
                            collaborators)}
                          selected={selectedsCollaborator}
                        />
                      </Flex>

                      {
                        errors.collaborators && (
                          <Text color="red"
                                fontSize={'12px'}>{errors.collaborators.message}</Text>
                        )
                      }
                    </Flex>
                  </InputGroup>
                </Flex>
              </Flex>
            </Flex>

            {
              props.type === 'company'
                ? (
                  <Flex
                    mt="15px"
                    flexDirection="column"
                  >
                    <CompanyFilterAndTable
                      guid_client={props.guid_client}
                      error={errors.companies ? true : false}
                    setSelectedCompanies={setCompanyChecked}
                  />
                </Flex>
              ) :
              (
                <Flex 
                  mt="15px"
                  flexDirection="column"
                >
                  <Flex
                    alignItems="stretch"
                    direction="column"
                    gap={2}
                    flexGrow={1}
                    mt={8}
                    mb={4}
                  >
                    <CertificateStaticFilterAndTable
                      guid_client={props.guid_client}
                      error={errors.certificates ? true : false}
                      setSelectedCertificates={setCertificateChecked}
                      dataCertificate={props.dataCertificate}
                    />
                  </Flex>
                </Flex>
              )
            }
          </ModalBody>

          <ModalFooter borderTop={'1px solid #CBD5E0'} mt={5}>
            <Button
              bgColor="white"
              color="#171923"
              _hover={{ bg: 'white', textDecoration: 'underline' }}
              onClick={onClose}
              mr={5}
              fontSize={'14px'}
              fontFamily={'Poppins-Medium'}
              fontWeight={400}
            >
              Fechar
            </Button>

            <Button
              bgColor="#4B4EFF"
              color="white"
              width={104}
              height={37}
              _hover={{ bg: '#282be0' }}
              onClick={handleSubmit(handleFormInfo)}
              fontSize={'14px'}
              fontFamily={'Poppins-Medium'}
              fontWeight={400}
              disabled={disabledButtonSave}
              isDisabled={disabledButtonSave}
              isLoading={disabledButtonSave}
            >
              Criar tarefa
            </Button>
          </ModalFooter>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
