export const allPermissions = [
  {
    id: "org",
    name: "Organização",
    description: "Acesso geral a organização.",
    available: [
      {
        id: "admin",
        description: "Acesso de administrador a organização",
      }
    ]
  },
  {
    id: "user",
    name: "Usuários",
    description: "Relativo a área de usuários, e convites.",
    available: [
      {
        id: "read",
        description: "Permissão para listar todos os usuários relacionados a empresa selecionada"
      },
      {
        id: "write",
        description: "Convidar novos usuários para o sistema"
      },
      {
        id: "delete",
        description: "Possibilidade de Bloquear usuários no sistema"
      }
    ]
  },
  {
    id: "company",
    name: "Empresas",
    description: "Acesso geral a registro e edição de empresas.",
    available: [
      {
        id: "read",
        description: "Acessar a lista de subsidiarias cadastradas da empresa selecionada.",
      },
      {
        id: "write",
        description: "Cadastrar novas empresas subsidiarias, ou novas empresas no sistema.",
      },
      {
        id: "delete",
        description: "Desativar empresas.",
      }
    ]
  },
  {
    id: "kanban",
    name: "Kanban de Obrigações",
    description: "Acesso e manipulação das Obrigações.",
    available: [
      {
        id: "read",
        description: "Acessar a listagem de obrigações do Kanban.",
      },
      {
        id: "write",
        description: "Criar novas obrigações como editar obrigações já cadastradas",
      },
      {
        id: "delete",
        description: "Remover Obrigações",
      }
    ]
  },
  {
    id: "persona",
    name: "Pessoa Física",
    description: "Acesso geral a registro e edição de pessoa física.",
    available: [
      {
        id: "read",
        description: "Acessar a lista de pessoas cadastradas.",
      },
      {
        id: "write",
        description: "Cadastrar novas pessoas no sistema.",
      },
      {
        id: "delete",
        description: "Desativar pessoas.",
      }
    ]
  },
  {
    id: "config",
    name: "Configurações",
    description: "Acesso geral as configurações do sistema.",
    available: [
      {
        id: "read",
        description: "Acessar as configurações.",
      },
      {
        id: "write",
        description: "Edite as configurações.",
      },
      {
        id: "delete",
        description: "Capacidade de ativar/desativar produtos.",
      }
    ]
  },
  {
    id: "cnd",
    name: "Certidões",
    description: "Acesso geral ao registro e requisição de certidões, acesso a listagem e manipulação.",
    available: [
      {
        id: "read",
        description: "Acessar a lista de certidões.",
      },
      {
        id: "write",
        description: "Criar novas certidões no sistema bem como requisitar consulta de certidões no servidor central da Taxly.",
      },
      {
        id: "delete",
        description: "Deletar certidões inseridas manualmente no sistema.",
      }
    ]
  },
  {
    id: "taxCurrentAccount",
    name: "Conta Corrente Fiscal",
    description: "Acesso geral ao registro e requisição de relatórios de Conta Corrente Fiscal, acesso a listagem e manipulação.",
    product: 'tax-status',
    available: [
      {
        id: "read",
        description: "Acessar a lista de relatórios de Conta Corrente Fiscal.",
      },
      {
        id: "write",
        description: "Criar novos relatórios de Conta Corrente Fiscal no sistema bem como requisitar consulta de relatórios no servidor central da Taxly.",
      }
    ]
  },
  {
    id: "responsibles",
    name: "Responsáveis",
    description: "Adicionar/Remover Responsáveis dos produtos.",
    available: [
      {
        id: "read",
        description: "Acessar a lista de responsáveis.",
      },
      {
        id: "write",
        description: "Vincular novos responsáveis.",
      }
    ]
  },
];