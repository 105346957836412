import { ITaxCurrentAccountMultiDownloadForm } from "../pages/dashboard/TaxCurrentAccount/modal/TaxCurrentAccountMultiDownload";
import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { IAddNewRegisterRequest, IFilterListTaxCurrentAccount, IIssueBatchCertificatesCompanies, ITaxCurrentAccountExportResponse, ITaxCurrentAccountHistory, ITaxCurrentAccountList } from "./interface/taxCurrentAccount.interface";

export const listTaxCurrentAccount = async (guid_client: string, filters: IFilterListTaxCurrentAccount): Promise<TPremiseResponse<ITaxCurrentAccountList>> => {
  const url = `/tax-current-account/list/${guid_client}`;
  return await new ApiCall(url, ApiAuth()).post<ITaxCurrentAccountList, IFilterListTaxCurrentAccount>(filters);
};

export const searchTaxCurrentAccount = async (guid_client: string, guid_company: string, type: string): Promise<TPremiseResponse<boolean>> => {
  const url = `/tax-current-account/issue/${guid_client}/${guid_company}/${type}`;
  return await new ApiCall(url, ApiAuth({timeout: '1200000'})).get<boolean>();
}

export const getTaxCurrentAccountHistory = async (guid_client: string, guid_company: string, type: string): Promise<TPremiseResponse<ITaxCurrentAccountHistory[]>> => {
  const url = `/tax-current-account/list/history/${guid_client}/${guid_company}/${type}`;
  return await new ApiCall(url, ApiAuth()).get<ITaxCurrentAccountHistory[]>();
}

export const exportTaxCurrentAccount = async (guid_client: string, filters: IFilterListTaxCurrentAccount&ITaxCurrentAccountMultiDownloadForm): Promise<TPremiseResponse<ITaxCurrentAccountExportResponse>> => {
  const url = `/tax-current-account/export/${guid_client}`;
  return await new ApiCall(url, ApiAuth()).post<ITaxCurrentAccountExportResponse, IFilterListTaxCurrentAccount&ITaxCurrentAccountMultiDownloadForm>(filters);
}

export const addNewRegister = async (guid_client: string, data: IAddNewRegisterRequest): Promise<any> => {
  const url = `/tax-current-account/add/${guid_client}`;
  return await new ApiCall(url, ApiAuth({ "Content-Type": "multipart/form-data" })).post<any, IAddNewRegisterRequest>(data);
}

export const issueBatchTaxCurrentAccountCompanies = async (guid_client: string, data: IIssueBatchCertificatesCompanies[]): Promise<TPremiseResponse<any>> => {
  const url = `/tax-current-account/issue-batch/${guid_client}`;
  return await new ApiCall(url, ApiAuth()).post<ITaxCurrentAccountExportResponse, IIssueBatchCertificatesCompanies[]>(data);
}