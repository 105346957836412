import { useEffect, useState } from "react";
import AlertToast from "../../../../../components/AlertToast/AlertToast";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import {
  getBulkUpdateByBulkType,
} from "../../../../../_services/bulk-update.service";
import { EToastStatus } from "../../../../../components/ToastEnum";
import { useToast } from "@chakra-ui/react";
import { Alert } from "../../../../../components/Alert";
import {
  BulkUpdateItemCertificateStatus,
  IGetBulkUpdateResponse,
} from "../../../../../_services/interface/bulk-update.interface";
import BulkUpdateCertificatesModal from "./BulkUpdateCertificatesModal";
import { getCancelToken } from "../../../../../_services/cancelToken";
interface IBulkUpdateCertificatesProps {
  guid_client: string;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  location: string;
  bulkUpdateStarted: boolean;
  setCanShowRetrieve: React.Dispatch<React.SetStateAction<boolean>>;
}

const BulkUpdateCertificates = ({
  guid_client,
  socket,
  location,
  bulkUpdateStarted,
  setCanShowRetrieve,
}: IBulkUpdateCertificatesProps) => {
  const BULK_TYPE = "certificate";

  const toast = useToast();

  const [showInfoToast, setShowInfoToast] = useState<boolean>();
  const [updateInfoToast, setUpdateInfoToast] = useState<boolean>();
  const [showTable, setShowTable] = useState<boolean>(false);
  const [bulkUpdateRetryStarted, setBulkUpdateRetryStarted] = useState<boolean>(false);

  const [refreshBulkUpdate, setRefreshBulkUpdate] = useState<boolean>(false);
  
  const [totalItems, setTotalItems] = useState<number>(0);
  const [itemsFinished, setItemsFinished] = useState<number>(0);

  const [bulkUpdate, setBulkUpdate] = useState<IGetBulkUpdateResponse|null>(null);

  useEffect(() => {
    fetchBulkUpdateByType(guid_client);
  }, [guid_client]);

  useEffect(() => {
    if (refreshBulkUpdate) {
      fetchBulkUpdateByType(guid_client);
      setRefreshBulkUpdate(false);
    }
  }, [refreshBulkUpdate]);

  useEffect(() => {
    if (totalItems > 0 && totalItems === itemsFinished) {
      fetchBulkUpdateByType(guid_client);
    }
  }, [totalItems, itemsFinished]);

  useEffect(() => {
    if (location !== "CertificateManagerPanel") {
      setShowInfoToast(false);
    }
  }, [location]);

  useEffect(() => {
    if (bulkUpdateStarted || bulkUpdateRetryStarted) {
      setBulkUpdateRetryStarted(false);
      setCanShowRetrieve(false);
      fetchBulkUpdateByType(guid_client);
      setShowInfoToast(true);
    }
  }, [bulkUpdateStarted, bulkUpdateRetryStarted]);

  useEffect(() => {
    if (updateInfoToast) {
      setUpdateInfoToast(false);
    }
  }, [updateInfoToast]);

  useEffect(() => {
    if (socket) {
      socket.off("refreshBulkCertificate");
      activeWebsocket(socket);
    }
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (showTable) {
      fetchBulkUpdateByType(guid_client);
      setUpdateInfoToast(false);
    }
  }, [showTable]);

  const fetchBulkUpdateByType = async (guid_client: string) => {
    if (guid_client && BULK_TYPE) {
      const filters = {
        page: 1,
        row_by_page: -1,
        order_field: 'guid_cnd',
        order_direction: 'asc',
      };

      const { status, response } = await getBulkUpdateByBulkType(
        guid_client,
        BULK_TYPE,
        filters,
        getCancelToken('bulkUpdate.BulkUpdateByType')
      );

      if (status && status !== 204 && status !== 200 && status !== 404) {
        toast({
          title: "Falha ao obter dados da página.",
          description: "Entre em contato com o suporte.",
          status: EToastStatus.ERROR,
          duration: 5000,
          isClosable: true,
        });
        setBulkUpdate(null);
      }

      if (status && status === 404) {
        toast.closeAll();
        setCanShowRetrieve(true);
        setBulkUpdate(null);
      }

      if (status && status === 200) {
        setCanShowRetrieve(false);
        const bulkResponse = response as IGetBulkUpdateResponse
        setBulkUpdate(bulkResponse);
        setTotalItems(bulkResponse.items.data[0].totalItems);
        setItemsFinished(bulkResponse.items.data.filter((i) => i.status !== BulkUpdateItemCertificateStatus.PENDING).length);
      }
    }
  };

  const activeWebsocket = (
    socket: Socket<DefaultEventsMap, DefaultEventsMap>
  ) => {
    socket.on("refreshBulkCertificate", (message) => {
      if (
        message.type === "refreshBulkCertificate" &&
        location === "CertificateManagerPanel"
      ) {
        const messageObj = JSON.parse(message.message);
        if (messageObj.guid_bulk_update && messageObj.guid_client && messageObj.totalItems && messageObj.totalFinished) {
          if (messageObj.guid_client === guid_client) {
            setTotalItems(+messageObj.totalItems);
            setItemsFinished(+messageObj.totalFinished);
          }
        }
      }
    });
  };

  const shouldShowTable = () => {
    setShowInfoToast(false);
    toast.closeAll();
    setShowTable(true);
  }

  const errorsItems = () =>
    bulkUpdate?.items.data.filter((i) =>
      [
        BulkUpdateItemCertificateStatus.ERROR,
        BulkUpdateItemCertificateStatus.FAILED,
      ].includes(i.status)
    ).length;

  const showUpdatedToast = () => {
    if (location !== "CertificateManagerPanel") {
      return;
    }
    const hideToastAndShowAlertOnTop = () => {
      setShowInfoToast(false);
    };

    let closableFunc = hideToastAndShowAlertOnTop;
    let className = "alert-instance";
    let status: "warning" | "success" | "error" | "info" = "info";
    let descriptionHtml = `Atualizando <strong>${itemsFinished + 1} de ${totalItems}</strong> ${totalItems > 1 ? 'certidões' : 'certidão'}.`;
    let clicable = { action: () => shouldShowTable(), text: "Ver Detalhes" };
    let type: "two" | "three" | "one" = "three";
    let variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent" = "alert";

    if (itemsFinished === totalItems) {
      status = "success";
      descriptionHtml = `<strong>${itemsFinished} de ${totalItems}</strong> ${totalItems > 1 ? 'certidões' : 'certidão'} atualizadas.`;

      if (!!errorsItems()) {
        status = "warning";
        descriptionHtml = `Erro ao atualizar <strong>${errorsItems()} de ${totalItems}</strong> ${totalItems > 1 ? 'certidões' : 'certidão'}.`;
      }
    }

    return (
      <AlertToast
        closableFunc={closableFunc}
        className={className}
        status={status}
        descriptionHtml={descriptionHtml}
        clicable={clicable}
        type={type}
        variant={variant}
      />
    );
  };

  const showUpdatedPanel = () => {
    toast.closeAll();

    let className = "alert-instance";
    let status: "warning" | "success" | "error" | "info" = "info";
    let descriptionHtml = `<strong>${itemsFinished} de ${totalItems}</strong> certidões já foram atualizadas.`;
    let clicable = { action: () => shouldShowTable(), text: "Ver Detalhes" };
    let type: "two" | "three" | "one" = "three";
    let variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent" = "alert";
    let justify: "start" = "start";

    if (itemsFinished === totalItems) {
      status = "success";
      descriptionHtml = `<strong>${itemsFinished} de ${totalItems}</strong> certidões atualizadas.`;

      if (!!errorsItems()) {
        status = "warning";
        descriptionHtml = `Erro ao atualizar <strong>${errorsItems()} de ${totalItems}</strong> certidões.`;
      }
    }

    return (
      <>
        <Alert
          isClosable={false}
          className={className}
          status={status}
          descriptionHtml={descriptionHtml}
          clicable={clicable}
          type={type}
          variant={variant}
          justify={justify}
        />
      </>
    );
  };

  const showBulkUpdateTable = () => {
    return (
      <>
        <BulkUpdateCertificatesModal
          guid_client={guid_client}
          bulkUpdate={bulkUpdate as IGetBulkUpdateResponse}
          showTable={showTable}
          setShowTable={setShowTable}
          setRefreshBulkUpdate={setRefreshBulkUpdate}
          totalItems={totalItems}
          totalFinished={itemsFinished}
          totalErrors={errorsItems() ?? 0}
          setBulkUpdateRetryStarted={setBulkUpdateRetryStarted}
        />
      </>
    );
  };

  return (
    <>
      {!!bulkUpdate && totalItems > 0
        ? showTable
          ? showBulkUpdateTable()
          : !showInfoToast
          ? showUpdatedPanel()
          : showUpdatedToast()
        : null}
    </>
  );
};

export default BulkUpdateCertificates;
