import { Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BlockSwitch } from "../../../../components/BlockSwitch";
import { FormButton } from "../../../../components/login/button.component";
import { getClientCompanies } from "../../../../_services/company.service";
import { configTaxCurrentAccount, getConfigTaxCurrentAccount } from "../../../../_services/config.service";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { ICertificateConfigPJ } from "./Certificate";
import { CertificateRecurrency, recurrencyIsEquals } from "./CertificateRecurrency";

interface ITaxCurrentAccountProps {
  refreshData: boolean;
  guid_client: string;
  certificates: ICertificate[];
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;

  canEdit: boolean;
  canDelete: boolean;
}

export const TaxCurrentAccount = (props: ITaxCurrentAccountProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  
  const [certificatesActive, setCertificatesActive] = useState<string[]>([]);
  const [initialCertificatesActive, setInitialCertificatesActive] = useState<string[]>([]);

  const [certificatesPJConfig, setCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  const [initialCertificatesPJConfig, setInitialCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);

  const submit = async () => {
    setSubmitDisabled(true);

    const hasLineWithoutRecurrencyOrCompany = certificatesPJConfig.some(config => {
      if (config.recurrency && config.guid_companies.length === 0) {
        return true;
      }

      if (!config.recurrency && config.guid_companies.length > 0) {
        return true;
      }
    });

    if(hasLineWithoutRecurrencyOrCompany) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Existem linhas sem recorrência ou empresa selecionada',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      setSubmitDisabled(false);
      return;
    }

    const {status, response} = await configTaxCurrentAccount(props.guid_client,{
      productsCompany: certificatesPJConfig,
      slugsActive: certificatesActive
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.hasChangeHook(false);

      props.setSubmit(false);
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });

      props.setSubmitError(true);
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }

  const getAllCompanies = async () => {
    const { status, response } = await getClientCompanies(props.guid_client);
    if (status === 200) {
      if (Array.isArray(response)) {
        setAllCompanies(response);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
  }

  const configFields = async () => {
    setIsLoading(true);
    const {status, response} = await getConfigTaxCurrentAccount(props.guid_client);

    if(
      status === 200 
      && response
      && 'productsCompany' in response
      && 'slugsActive' in response
    ) {
      setCertificatesPJConfig(response.productsCompany);
      setInitialCertificatesPJConfig(response.productsCompany);
      setCertificatesActive(response.slugsActive);
      setInitialCertificatesActive(response.slugsActive);
    }

    setTimeout(() => setIsLoading(false), 500);
  }

  const handleSetCompaniesRecurrency = (
    newCompaniesRecurrency: ICertificateConfigPJ[],
    certificate: ICertificate
  ) => {
    const { slug } = certificate;

    const certificatesPJConfigByCertificate = certificatesPJConfig.filter(
      (config) => config.slug === slug
    );

    const isEquals = recurrencyIsEquals(newCompaniesRecurrency, certificatesPJConfigByCertificate);

    if(isEquals) {
      return false;
    }

    const certificatesPJ = [
      ...certificatesPJConfig.filter((config) => config.slug !== slug),
      ...newCompaniesRecurrency,
    ]
  
    setCertificatesPJConfig(certificatesPJ);
  };

  const changeStatusCertificate = (value: boolean, slug: string) => {
    if(value) {
      setCertificatesActive([...certificatesActive, slug]);
    } else {
      setCertificatesActive(certificatesActive.filter(value => value !== slug));
    }
  }

  useEffect(() => {
    props.hasChangeHook(false);

    if (certificatesActive.sort().join() !== initialCertificatesActive.sort().join()) {
      props.hasChangeHook(true);
    }

    if (!recurrencyIsEquals(certificatesPJConfig, initialCertificatesPJConfig)) {
      props.hasChangeHook(true);
    }
  }, [
    certificatesPJConfig,
    initialCertificatesActive
  ]);

  useEffect(() => {
    if(props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    configFields();
    getAllCompanies();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  return (
    !certificatesActive.some((slug) => props.certificates.some((certificate) => certificate.slug === slug)) ? (
      <Flex direction="column" w="100%">
        <Flex marginBottom="32px">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Gerenciador de conta corrente fiscal (Em Breve)</Text>
        </Flex>
      </Flex>
    ) : (<>
      <Flex direction="column" w="100%">
        <Flex marginBottom="32px">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Gerenciador de conta corrente fiscal</Text>
        </Flex>
  
        <Flex width="100%" flexDirection="row" gap="24px" flexWrap="wrap" justifyContent="flex-start">
          {
            isLoading ? (
              Array.from({ length: 1 }, (_, index) => (
                <BlockSwitch
                  key={index}
                  isActive={false}
                  setIsActive={(value: boolean) => null}
                  name="carregando"
                  icon="/icons/gerenciado_cnds.png"
                  isLoading={true}
                />
              ))
            ) : (
              props.certificates.map((certificate, index) => (
                <BlockSwitch
                  key={certificate.slug || index}
                  isActive={certificatesActive.includes(certificate.slug)}
                  // Esta bloqueado pois ainda não é permitido que o usuario ative ou desative esse produto de forma autônoma.
                  // setIsActive={(value: boolean) => changeStatusCertificate(value, certificate.slug)}
                  setIsActive={(value: boolean) => null}
                  name={certificate.name}
                  icon="/icons/gerenciado_cnds.png"
                  minWidth="260px"
                  disabled={!props.canDelete}
                />
              ))
            )
          }
  
        </Flex>
  
        <Flex margin="32px 0">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para empresas</Text>
        </Flex>
  
        <Flex flexDirection="column" w="100%">
          {
            props.certificates
              .filter((certificate) => certificate.config_company === 'ALL' || certificate.config_company === 'AUTOMATIC')
              .flatMap((certificate, index) => {

                if (certificate.children?.some(child => !child.databroker_name || child.databroker_name === '')) {
                  return (
                      certificate.children
                      .sort((a, b) => (a.slug === 'cnd_federal' ? -1 : b.slug === 'cnd_federal' ? 1 : 0))
                      .filter(child => certificatesActive.includes(child.slug))
                      .map((child, i) => {
                        return (<>
                          <CertificateRecurrency
                            certificate={child}
                            allCompanies={allCompanies.filter(company => company.hasDigitalCertificate || company.has_power_of_attorney)}
                            companiesRecurrency={certificatesPJConfig.filter(config => config.slug === child.slug)}
                            setCompaniesRecurrency={handleSetCompaniesRecurrency}
                            showHeader={index === 0 && i === 0}
                            canEdit={props.canEdit}
                            entity="company"
                            customAlertMessage="Caso a empresa não tenha procuração e/ou certificado digital, não será possível ativar a sua pesquisa recorrente."
                          />

                          <hr />
                        </>)
                      })

                    )
                } else {
                  if(!certificatesActive.includes(certificate.slug)) {
                    return (<></>);
                  }

                  return (<>
                    <CertificateRecurrency
                      certificate={certificate}
                      allCompanies={allCompanies.filter(company => company.hasDigitalCertificate || company.has_power_of_attorney)}
                      companiesRecurrency={certificatesPJConfig.filter(config => config.slug === certificate.slug)}
                      setCompaniesRecurrency={handleSetCompaniesRecurrency}
                      showHeader={index === 0}
                      canEdit={props.canEdit}
                      entity="company"
                      customAlertMessage="Caso a empresa não tenha procuração e/ou certificado digital, não será possível ativar a sua pesquisa recorrente."
                    />
                    <hr />
                  </>)
                }
            })
          }
        </Flex>
  
        <hr />
  
        <Flex width="100%" justifyContent="flex-start" marginTop="35px">
          <FormButton onClick={submit} disabled={submitDisabled || isLoading}>
            Salvar alterações
          </FormButton>
        </Flex>
      </Flex>
    </>)
  );
}
