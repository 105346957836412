import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Image } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { ICNDListData, IFilterListCertificate } from "../../../../_services/interface/cnd.interface";
import { ViewPdfModal } from "./ViewPdfModal";
import { statusForTagList } from "../util/cndUtils";
import { IColumn, IData, ITableMetaData } from "../../../../components/Table/table.interface";
import { Datatables } from "../../../../components/Table/Datatables";
import { listCertificateHistoryData, listCnds } from "../../../../_services/cnd.service";
import { stateRegionForTag } from "../../../../components/Table/utils";
import debounce from 'lodash/debounce';
import { getCancelToken } from "../../../../_services/cancelToken";

interface IViewCertificatesModalProps {
  guid_client: string;
  title: string,
  isOpen: boolean;
  onClose: () => void;
  type: 'vigentes'|'vencer'|'vencidas'|'renovadasAuto'|'renovadasManual'|'renovadasTotal'|'vigenciaTotal'|'general'|null;
  filter?: IFilterListCertificate
}

export const ViewCertificatesModal = (props: IViewCertificatesModalProps) => {
  const [cndData, setCNDData] = useState<IData<any>|null>(null);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [currentFile, setCurrentFile] = useState<string>('');
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const debouncedRefreshData = useCallback(debounce(() => setRefreshData(true), 400), []);
  useEffect(() => {
    debouncedRefreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, metaData]);


  useEffect(() => {
    if(refreshData) {
      if (props.type) {
        if (props.type !== 'general') {
          fetchCertificateDataGraph();
        } else {
          fetchCertificateDataGeneral();
        }
      } else {
        setCNDData(null);
      }
      setRefreshData(false);
    }
  }, [refreshData]);

  const fetchCertificateDataGraph = async () => {
    if (props.guid_client && props.type) {
      const defaultQuery: IFilterListCertificate = {
        page: metaData?.currentPage ? Number(metaData.currentPage) : 1,
        row_by_page: metaData?.rowByPage ? Number(metaData.rowByPage) : -1,
        order_field: metaData?.orderField ? metaData.orderField : props.type.includes('renovada') ? 'issuedAt' : 'validUntil',
        order_direction: metaData?.orderDirection ? metaData.orderDirection : 'asc',
      };

      const type: 'vigentes'|'vencer'|'vencidas'|'renovadasAuto'|'renovadasManual'|'renovadasTotal'|'vigenciaTotal' = props.type as 'vigentes'|'vencer'|'vencidas'|'renovadasAuto'|'renovadasManual'|'renovadasTotal'|'vigenciaTotal';
      const query: IFilterListCertificate = props.filter ? 
      {
        ...props.filter,
        ...defaultQuery,
      } : defaultQuery;
      const { status, response } = await listCertificateHistoryData(props.guid_client, query, type, getCancelToken('certificate.dataGraph'));
      
      if (status && status === 200 && 'data' in response && 'meta' in response) {
        setCNDData(response);
      } else {
        setCNDData(null);
      }
    }
  };

  const fetchCertificateDataGeneral = async () => {
    if (props.guid_client && props.type) {
      const defaultQuery: IFilterListCertificate = {
        page: metaData?.currentPage ? Number(metaData.currentPage) : 1,
        row_by_page: metaData?.rowByPage ? Number(metaData.rowByPage) : -1,
        order_field: metaData?.orderField ? metaData.orderField : 'createdAt',
        order_direction: metaData?.orderDirection ? metaData.orderDirection : 'asc',
      };

      const query: IFilterListCertificate = props.filter ? 
      {
        ...props.filter,
        ...defaultQuery,
      } : defaultQuery;
      const { status, response } = await listCnds(props.guid_client, query, getCancelToken('certificate.dataGraph'));
      
      if (status && status === 200 && 'data' in response && 'meta' in response) {
        setCNDData(response);
      } else {
        setCNDData(null);
      }
    }
  };

  const haveFileAvailable = (cnd: ICNDListData): boolean => {
    const status = cnd.status;

    if(cnd.guid_file && status && status.show_file && cnd.slug_status !== 'error') {
      return true;
    }

    return false;
  }

  const columns = [
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: false,
      align: 'center',
      dynamicTag: {
        function: statusForTagList
      },
    },
    {
      title: 'Certidão',
      name: 'certificate_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Titular',
      name: 'entity.displayName',
      orderFieldName: 'entity_name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'CNPJ/CPF',
      name: 'entity.documentFormatted',
      orderFieldName: 'entity_document',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Estado',
      name: 'state.initials',
      orderFieldName: 'state_initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state.initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city.name',
      orderFieldName: 'city_name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Emissão',
      name: 'issuedAtFormatted',
      orderFieldName: 'issuedAt',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Vencimento',
      name: 'validUntilFormatted',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Consulta',
      name: 'createdAtFormatted',
      orderFieldName: 'createdAt',
      sortable: true,
      align: 'center',
    },
  ] as IColumn[];

  const actions = [
    {
      text: 'Visualizar arquivo',
      icon: <Image src="/icons/table/eye.svg" width={5} height="auto" />,
      isVisible: (cnd: ICNDListData): boolean => {return true},
      isDisabled: (cnd: ICNDListData): boolean => { return !haveFileAvailable(cnd) },
      action: (cnd: ICNDListData) => {
        setCurrentFile(cnd.file||'');
        setOpenFileModal(true);
      },
    },
  ]

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose} size="6xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">
            <Flex w="100%" alignItems="center" display="flex">
              <Flex>{props.title}</Flex>
            </Flex> 
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>

              <Datatables<any>
                name="Certidões"
                columns={columns}
                actions={actions}
                metaDataHook={setMetaData}
                data={cndData}
                orderField={props.type === 'general' ? 'createdAt' : props.type?.includes('renovada') ? 'issuedAt' : 'validUntil'}
                orderDirection={'asc'}
                heigth="400px"
                removeAllOption={true}
              />

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={props.onClose} width="100%">Fechar</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ViewPdfModal
        title="Visualizar Certidão" 
        file={currentFile} 
        openModal={openFileModal}
        openModalHook={setOpenFileModal}
      />
    </>
  );
}
